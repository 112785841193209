import Layout from "../components/Layouts/Layout";

const ProductThanks = () => {
    return (
        <Layout title={'Purity Cottage Skincare | Thank you for your order'}>
            <section className="container py-5 mt-5">
                <div className="row text-center">
                    <div className="col-lg-7 col-md-7 col-sm-10 mx-auto">
                        <h1> Thank you for your product purchase.</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto mt-2 text-center">
                        <p>We have received your order and will ship it out as soon as possible. </p>
                        <p>You may view the status of your order in your dashboard</p>
                        <p>If you subscribed to our P.C.S. Glow Club membership, thank you! This is just a temporary page for acknowledgement</p>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ProductThanks;