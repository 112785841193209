import {useEffect, useState} from "react";
import axios from "axios";

const ProductsApi = () => {
    const [products, setProducts] = useState([])
    const [callback, setCallback] = useState(false)
    const [category, setCategory] = useState('')
    const [brand, setBrand] = useState('')
    const [sort, setSort] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)
    const [searchResults, setSearchResults] = useState(0)


        const getProducts = async (callback, category, brand, sort, searchTerm, page) => {
            try {
                const res = await axios.get(`/api/products?limit=${page * 6}&${category}&${sort}&${brand}&name[regex]=${searchTerm}`) // Change 6 in LoadMore.js to match
                setProducts(res.data.products)
                setSearchResults(res.data.results)
            } catch (err) {
                console.log(err)
            }

        }

    useEffect(() => {
         getProducts(callback, category, brand, sort, searchTerm, page)

    }, [callback, category, brand, sort, searchTerm, page])


    return {
        products: [products, setProducts],
        callback: [callback, setCallback],
        category: [category, setCategory],
        brand: [brand, setBrand],
        sort: [sort, setSort],
        searchTerm: [searchTerm, setSearchTerm],
        page: [page, setPage],
        searchResults: [searchResults, setSearchResults]
    }
};

export default ProductsApi;