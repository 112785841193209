import styles from './Hero.module.css'
import {useEffect, useState} from "react";
import useWindowDimensions from "../../api/windowApi";

const Hero = () => {
    const [windowHeight, setWindowHeight] = useState('')
    const [windowWidth, setWindowWidth] = useState('')

    const {height, width} = useWindowDimensions()
    useEffect(() => {
        setWindowHeight(height)
        setWindowWidth(width)
    },[height, width])


    return (
        <div className={styles.hero}>
            <img src={'https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_1920/v1623942697/PCSv4/hero_ahoyj5_pa0363.jpg'}
                 width={windowWidth} height={(windowHeight / 1.7)} alt={'Welcome to Purity Cottage Skincare'}/>
            <h1>Welcome to Purity Cottage Skincare</h1>
        </div>
    )
};

export default Hero;