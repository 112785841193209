import Layout from "../components/Layouts/Layout";

const GuidelinesPage = () => {
    return (
        <Layout title={`Purity Cottage Skincare | Guidelines`} description={`PurityCottage.com: Guidelines for your service.`}>
            <section id="guidelines" className="container py-5">
                <div className="guidelines-heading">
                    <h1 className="h1 text-center">Guidelines</h1>
                </div>
                <ul className="list-group list-group-flush mb-5">
                    <li className="list-group-item">Purity Cottage is located inside the Salon Lofts at Johns Creek Promenade  11300 Medlock Bridge Rd. Duluth Georgia, 30096 Suite 320
                    </li>
                    {/*<li className="list-group-item">We require a non-refundable deposit of $20 for any service booked*/}
                    {/*    and 50% deposit*/}
                    {/*    for our hostess programs <small className="text-muted">(This amount will go towards your total*/}
                    {/*        of service*/}
                    {/*        price).</small></li>*/}
                    <li className="list-group-item">Should you need to cancel or reschedule your appointment, we ask for
                        24 hour
                        notification in advance.
                    </li>
                </ul>

                <div className="row" id="covid">
                    <div className="col-lg-8 col-md-8 col-sm-10 mx-auto text-center">
                        <h4 className="h4 my-2 text-danger">*Please read about our Covid-Safe practices*</h4>
                        <p className="">Purity Cottage understands the importance of sanitation and disinfection. Below
                            you will
                            find the steps we
                            take to ensure our clients remain safe and relaxed.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mx-auto">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Mask will be worn by esthetician during the entire
                                service.
                            </li>
                            <li className="list-group-item">Hands will be washed upon entry and after setup.</li>
                            <li className="list-group-item">A full wipe down on all surfaces and items with EPA
                                registered products
                                during setup and breakdown.
                            </li>
                            <li className="list-group-item">Face shield, mask, and gloves will be used during the
                                service.
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="cdc" className="mt-2">
                    <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 mx-auto">

                            <p className="text-muted">We are following the CDC guidelines. You can view their
                                guidelines <a className={'text-danger'} href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">here. </a>
                                Stay
                                Safe & Stay healthy. If you have any questions or concerns feel free to contact me.
                                Thanks for
                                considering Purity Cottage
                                Skincare!</p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
};

export default GuidelinesPage;