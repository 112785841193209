import styles from './About.module.css'

const About = () => {

    return (
        <section className="p-2" id="about">
            <div className="row" data-aos="zoom-in" data-aos-duration="700" aria-hidden="true">
                <div className="col-lg-5 col-md-4 mx-auto">
                    <img
                        src={'https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_450/v1620836009/PCSv4/Chasity_opiyjq.jpg'}
                        className={'img-fluid'} alt={'Welcome to Purity Cottage Skincare'}/>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 flex flex-col justify-center my-auto">
                    <h2 className="card-title text-center mt-2">Chasity Craft <span
                        className={`${styles.teal}`}>|</span> <small
                        className="text-muted h5">
                        Licensed Esthetician</small></h2>
                    <p className="align-middle">
                        <span className={styles.first}>A</span>t Purity Cottage, our goal is to give you 5-star service while
                        helping you accomplish your specific skincare goals. I perform each treatment using powerful
                        products and potent ingredients combined with my effective skincare regimen, which I adjust for
                        any skin type. Some of the services I offer are Customized Facials, Advanced treatments such as
                        (Microdermabrasion, Dermaplaning, Chemical Peels), and Waxing
                    </p>
                </div>
            </div>
        </section>
    )
};

export default About;
