import Layout from "../../components/Layouts/Layout";
import {useContext, useState, useEffect} from "react";
import {useParams, Link} from 'react-router-dom';
import {GlobalState} from "../../store/Globals";
import ProductItem from "../../components/Products/ProductItem";

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
import {FaHeart} from "react-icons/fa";

SwiperCore.use([Pagination]);

const ProductDetailsPage = () => {
    const params = useParams();
    const state = useContext(GlobalState);
    const [isLoggedIn] = state.userApi.isLoggedIn
    const [products] = state.productsApi.products;
    const [productDetails, setProductDetails] = useState([])
    const [isAdmin] = state.userApi.isAdmin;
    const [favorites] = state.userApi.favorites
    const addToCart = state.userApi.addToCart;
    const addToFavs = state.userApi.addToFavs;


    useEffect(() => {
        if (params.slug) {
            products.forEach(product => {
                if (product.slug === params.slug) {
                    setProductDetails(product)
                }
            })
        }
// if (products.category === productDetails.category && productDetails.name !== products.name) {
//
// }
    }, [params.slug, products])


    if (productDetails.length === 0) return null
    return (
        <Layout title={`Purity Cottage Skincare | ${productDetails.name}`} description={`${productDetails.description}`}
                keywords={`${productDetails.name}, ${productDetails.brand}, ${productDetails.category}`}>
            <section className="py-5 mt-5 container">
                <div className="row justify-center">
                    <div className="col-md-4 col-sm-12">
                        <img
                            src={productDetails.photoUrl === undefined ? productDetails.images.url : productDetails.photoUrl}
                            alt={productDetails.name} className="img-fluid"/>
                        {/*<img src={productDetails.images.url} alt={productDetails.name} className="img-fluid"/>*/}
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <h1 className="h2 text-muted">
                            {productDetails.name}</h1>
                        <h2 className="h1 lead">
                            {productDetails.brand}</h2>
                        <h2 className="h1 lead">
                            {productDetails.category}</h2>
                        <h2 className="h1 lead">
                            Size: {productDetails.size}</h2>
                        <p className={favorites.some(
                            fav => fav._id === productDetails._id
                        ) ? 'favorite' : ''} onClick={() => addToFavs(productDetails)} ><FaHeart/></p>

                        {/*{productDetails.sold > 0 && (*/}
                        {/*    <h2 className={'lead'}>Number Sold: {productDetails.sold}</h2>*/}
                        {/*)}*/}

                        {isLoggedIn ? <h2 className="lead">Price {productDetails.price}</h2> :
                            <Link className={' text-danger my-2'} to={`/user/login`}>Login to see price</Link>}


                        <p className="leading-relaxed mb-4"> {productDetails.description}</p>
                    </div>
                    <div className="col-md-4 col-sm-6">

                        {isLoggedIn ? (
                            <Link to={'/cart'}>
                                <button className="btn btn-sm btn-primary mx-2"
                                        onClick={() => addToCart(productDetails)}>Add To Cart
                                </button>
                            </Link>
                        ): (
                            <Link to={`/user/login`} className={`btn btn-sm btn-outline-dark`}>Login to Purchase</Link>
                        )}

                        {isAdmin && (
                            <>
                                <Link to={`/admin/product_edit/${productDetails.slug}`}
                                      className={'btn btn-sm btn-outline-dark mx-2'}>
                                    Edit
                                </Link>
                                <Link to={`/admin/product_delete/${productDetails.slug}`}
                                      className={'btn btn-sm btn-outline-danger ml-2'}>
                                    Delete
                                </Link></>
                        )}
                    </div>
                </div>
            </section>

            <section className={`related-products`}>
                <h3>Related Products</h3>

                <Swiper
                    spaceBetween={20}
                    pagination={true}
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            width: 640,
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                    }}
                >
                    {products.map((product) => {
                        return (product.category === productDetails.category && productDetails.name !== product.name ? (
                            <SwiperSlide key={product._id}>
                                <ProductItem related={true} key={product._id} product={product}/>
                            </SwiperSlide>
                        ) : null)
                    })}
                </Swiper>
            </section>

        </Layout>
    );
};

export default ProductDetailsPage;