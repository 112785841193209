import {useState} from "react";
import Layout from "../../components/Layouts/Layout";
import {Modal} from "@material-ui/core";
import {InlineWidget} from "react-calendly";

const WaxingServicesPage = () => {
    const [booking, setBooking] = useState(false);

    const createBooking = () => {
        setBooking(true)
    }

    const cancelBooking = () => {
        setBooking(false)
    }

    return (
        <Layout title={'Purity Cottage Skincare | Waxing Services'}
                description={'Purity Cottage Skincare full-body waxing services'}>
            <div className="container">
                <div className="mt-5 row">
                    <div className="col-md-12">
                        <h1 className="h1 text-center">Waxing Services</h1>
                    </div>
                </div>
            </div>

            <section className="container py-5">
                <table className="table striped responsive bordered">
                    <thead>
                    <tr>
                        <th>
                            Service
                        </th>
                        <th>
                            Time
                        </th>
                        <th>
                            Price
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Bikini</td>
                        <td>20 Minutes</td>
                        <td>$15</td>
                    </tr>
                    <tr>
                        <td>Brazilian</td>
                        <td>30 Minutes</td>
                        <td>$25</td>
                    </tr>
                    <tr>
                        <td>Brow wax (cleanup)</td>
                        <td>15 Minutes</td>
                        <td>$15</td>
                    </tr>
                    <tr>
                        <td className="">Stomach</td>
                        <td className="">10 Minutes</td>
                        <td className="">$15</td>
                    </tr>
                    <tr>
                        <td className="">Chest</td>
                        <td className="">20 Minutes</td>
                        <td className="">$30</td>
                    </tr>
                    <tr>
                        <td className="">Full Back</td>
                        <td className="">20 Minutes</td>
                        <td className="">$25</td>
                    </tr>
                    <tr>
                        <td className="">Chin</td>
                        <td className="">10 Minutes</td>
                        <td className="">$10</td>
                    </tr>
                    <tr>
                        <td className="">Ear</td>
                        <td className="">15 Minutes</td>
                        <td className="">$10</td>
                    </tr>
                    <tr>
                        <td className="">Underarm</td>
                        <td className="">10 Minutes</td>
                        <td className="">$15</td>
                    </tr>
                    <tr>
                        <td className="">Arm</td>
                        <td className="">20 Minutes</td>
                        <td className="">$20</td>
                    </tr>
                    <tr>
                        <td className="">Legs</td>
                        <td className="">20 / 30 Minutes</td>
                        <td className="">$20 / $40</td>
                    </tr>
                    <tr>
                        <td className="">Lip</td>
                        <td className="">10 Minutes</td>
                        <td className="">$10</td>
                    </tr>
                    <tr>
                        <td className="">Nose</td>
                        <td className="">10 Minutes</td>
                        <td className="">$10</td>
                    </tr>
                    </tbody>
                </table>
                <div className="flex mt-5">
                    <>
                        <Modal onClose={cancelBooking} open={booking}>

                            <InlineWidget url={'https://calendly.com/puritycottage/waxing-service'} onClose={cancelBooking}/>
                        </Modal>
                        <button className={'btn btn-outline-dark card-body-btn'}
                                onClick={createBooking}>
                            Book
                        </button>
                    </>
                </div>
            </section>

        </Layout>
    );
};

export default WaxingServicesPage;