import {useContext, useEffect, useState} from "react";
import {Link, useHistory} from 'react-router-dom'
import Layout from "../../components/Layouts/Layout";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {GlobalState} from "../../store/Globals";
import Loading from "../../components/Loading/Loading";


const RegisterPage = () => {
    const history = useHistory();
    const state = useContext(GlobalState)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [isLoggedIn] = state.userApi.isLoggedIn;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        isLoggedIn && history.push('/')
    }, [isLoggedIn, history])

    const checkPassword = () => {
        if (password === '') {
            toast('Password field can not be blank', {
                toastId: 'empty-password'
            })
            setPasswordMatch(false)
            return
        }
        if (password.length < 6) {
            toast('Password must be at least 6 characters', {
                toastId: 'password-length'
            })
            setPasswordMatch(false)
            return
        }
        if (password !== confirmPassword && password !== '' && confirmPassword !== '') {
            toast('Passwords do not match', {
                toastId: 'password-match'
            })
            toast.clearWaitingQueue()
            setPasswordMatch(false)
            return
        }
        if (password === confirmPassword) {
            setPasswordMatch(true)
        }
    }

    const checkValidEmail = async () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            toast('Invalid Email', {
                toastId: 'invalid-email'
            })
            toast.clearWaitingQueue()
            setEmailValid(false)
        } else {
            await checkEmail()
        }

        // return re.test(String(email).toLowerCase());
    }

    const checkEmail = async () => {
        const checkEmailUsed = await axios.post('/api/user/find', {email})
        if (checkEmailUsed.status === 200) {
            toast(checkEmailUsed.data.msg, {
                toastId: 'existing-user'
            })
            toast.clearWaitingQueue()
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }
    }

    // Register new user
    const registerNewUser = async e => {
        setLoading(true)
        e.preventDefault();
        let user = {
            name,
            email,
            password
        }
        if (passwordMatch) {
            setPasswordMatch(false)
            try {
                const registeredUser = await axios.post('/api/user/register', {...user});
                if (registeredUser) {
                    const loginUser = await axios.post('/api/user/login',
                        {...user}
                    )
                    if (loginUser) {
                        setLoading(false)
                        localStorage.setItem('pcs-login', true)
                        if (typeof window !== 'undefined') {
                            window.location.href = "/";
                        }
                    }
                }
            } catch (err) {
                toast.error(err.response.data.msg)
            }

        }
    }

    if (loading) {
        return (
            <Layout>
                <Loading/>
            </Layout>
        )
    }

    return (
        <>
            <Layout title={'Purity Cottage Skin Care | Register'}>
                <section className={`container`}>
                    <h1 className={`text-center`}>Register Page</h1>
                </section>

                {/* Toast Container */}
                <ToastContainer/>
                {/* End of Toast Container */}

                <section className={`container`}>
                    <form className={'col-lg-6 col-md-8 col-sm-10 mx-auto'} onSubmit={registerNewUser}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="name" className="form-control" id="name"
                                   onChange={e => setName(e.target.value)} value={name} placeholder="Guest Name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input type="email" className="form-control" id="email"
                                   aria-describedby="emailHelp" placeholder="Enter email"
                                   onChange={e => setEmail(e.target.value)} value={email} onBlur={checkValidEmail}/>
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                                anyone else.</small>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password"
                                   placeholder="Password" minLength={6} onChange={e => setPassword(e.target.value)} onBlur={checkPassword} value={password}/>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="passwordConfirm">Confirm Password</label>
                            <input type="password" className="form-control" id="passwordConfirm"
                                   placeholder="Password Confirm" onChange={e => setConfirmPassword(e.target.value)}
                                   onBlur={checkPassword} minLength={6} value={confirmPassword}/>
                        </div>
                        <p className={'mt-2'}>
                            Already have an account? <Link to={'/user/login'}><span
                            className={'text-danger'}>Login Here</span></Link>
                        </p>
                        <div className={'my-4'}>
                            <button type="submit" className="btn btn-outline-dark"
                                    disabled={!emailValid || !passwordMatch}>Register
                            </button>
                        </div>
                    </form>
                </section>
            </Layout>
        </>
    );
};

export default RegisterPage;