import {Link} from 'react-router-dom';
import styles from './ServiceCards.module.css'
import {useEffect, useState} from "react";
import useWindowDimensions from "../../api/windowApi";

export default function ServiceCards() {
    const [windowWidth, setWindowWidth] = useState('')

    const {height, width} = useWindowDimensions()
    useEffect(() => {
        setWindowWidth(width)
    },[height, width])
    return (
        <section className="d-flex justify-content-center align-items-center flex-column py-5 px-3">
            <div>
                <h2 className="h1 text-center">Skin
                    confidence
                    is the best confidence!</h2>
            </div>
            <div className={`${styles.serviceContainer} mt-4`}>
                <div className={`${styles.box} ${windowWidth <= 414 && 'my-3'}`}>
                    <h2>Facial</h2>
                    <h3>Facial Services</h3>
                    <p className="lead">Rejuvenate your skin with facials customized for your individual needs. Whether
                        you
                        need a
                        quick pick me up,
                        or suffering from dry/stressed skin, or just need skincare advice. Each service helps you
                        achieve
                        radiant, healthy skin.</p>
                    <Link to={"/services/facial"} className="btn  shadow-lg btn-block">Book Service</Link>

                </div>
                <div className={`${styles.box} ${width <= 414 && 'my-3'}`}>
                    <h2>Treatments</h2>
                    <h3>Advanced Treatments</h3>
                    <p className="lead">Go beyond customized facials and explore clinical advanced treatments. Chemical
                        peels,
                        microdermabrasion, and dermaplaning all provide a deeper exfoliation revealing brighter, fresher
                        more
                        youthful skin.</p>
                    <Link to={"/services/advanced"} className="btn  shadow-lg btn-block">Book
                        Service</Link>

                </div>
                <div className={`${styles.box} ${width <= 414 && 'my-3'}`}>
                    <h2>Waxing</h2>
                    <h3>Waxing Services</h3>
                    <p className="lead">Eliminate pesky hairs with a virtually painless experience. Using combinations
                        of
                        soft and
                        hard bead wax, that's formulated to remove hair on any part of the body while leaving your skin
                        feeling
                        soft and smooth.</p>
                    <Link to={"/services/waxing"} className="btn  shadow-lg btn-block">Book Service</Link>
                </div>
            </div>
        </section>
    )
}