import Layout from "../components/Layouts/Layout";
import ServiceCards from "../components/Home/ServiceCards";
import Hero from "../components/Home/Hero";
import About from "../components/Home/About";
import Giftcard from "../components/Home/Giftcard";

const HomePage = () => {

    return (
        <>
            <div>

                <Layout title={'Purity Cottage Skin Care | Home'}>

                    {/* Hero Image */}
                    <Hero/>

                    {/* Service Cards */}
                    <ServiceCards/>

                    {/* About */}
                    <About/>

                    {/* Giftcard */}
                    {/*<Giftcard/>*/}

                </Layout>
            </div>
        </>

    );
};

export default HomePage;