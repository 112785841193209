import {useContext, useEffect} from "react";
import {GlobalState} from "../../store/Globals";
import Layout from "../../components/Layouts/Layout";
import Loading from "../../components/Loading/Loading";
import ServiceItem from "../../components/Services/ServiceItem";
import Fountains from "../../components/Services/Fountains";

const AdvancedServicesPage = () => {
    const state = useContext(GlobalState)
    const [services] = state.servicesApi.services;
    const [callback, setCallback] = state.servicesApi.callback


    useEffect(() => {
    }, [callback, services])

    if (!services) return (
        <Layout>
            <Loading/>
        </Layout>
    )
    return (
        <Layout title={'Purity Cottage Skincare | Advanced Skincare Treatments'}
                description={'Purity Cottage Skincare advanced treatment services for your more problematic skin.'}>
            <section className={'text-center'}>
                <h1>Advanced Treatment Services</h1>
            </section>
            {/*<Search/>*/}

            <section className={`container d-flex justify-content-center flex-wrap`}>
                {
                    services.map((service, index) => {
                        return (service.category === 'advanced' && (
                            <ServiceItem key={service._id} service={service} callback={callback}
                                         setCallback={setCallback}/>
                        ))
                    })
                }
            </section>

            {services.length === 0 && (
                <>
                    <Loading/>
                    <h1 className="text-center">No Results Found!</h1>
                </>
            )}
            <Fountains/>
        </Layout>
    );
};

export default AdvancedServicesPage;