import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useState} from "react";
import styles from './Service.module.css'

const CreateServiceEditor = ({richText}) => {
    const initialState = EditorState.createEmpty()
    const [editorState, setEditorState] = useState(initialState)

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        richText(editorState.getCurrentContent())
    }

    // const uploadImage = async (file) => {
    //     try {
    //
    //         let formData = new FormData()
    //         formData.append('image', file)
    //
    //         // setLoading(true)
    //         const res = await axios.post('/upload/service', {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 Authorization: token
    //             }
    //         })
    //         console.log(res)
    //
    //     } catch (err) {
    //         toast.error(err.response.data.msg)
    //     }
    // }

    return (
        <div className={styles.editor}>

            <Editor editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editorClassName={`${styles.serviceEditor}`}
                    toolbarClassName="toolbar-class"
                    toolbar={{
                        inline: {inDropdown: true},
                        list: {inDropdown: true},
                        textAlign: {inDropdown: true},
                        link: {inDropdown: true},
                        history: {inDropdown: true},
                        // image: { uploadCallback: uploadImage, alt: { present: true, mandatory: true } }
                    }}

            />
        </div>
    );
};

export default CreateServiceEditor;