import { PayPalButton } from "react-paypal-button-v2";

const MembershipBtn = ({currency, createSubscription, onApprove, catchError,onError, onCancel}) => {
    const paypalKey = 'AdjZuQlaYzuS_BaAKZhrzOoNLDi03cosnSZszFsl9AOwgeGjwKZ-y-u5FFZw8Si9PjIDOC--G4bYomvB'
    return (
        <PayPalButton
            currency={currency}
            createSubscription={(data, details) => createSubscription(data, details)}
            onApprove={(data, details) => onApprove(data, details)}
            onError={(err) => onError(err)}
            catchError={(err) => catchError(err)}
            onCancel={(err) => onCancel(err)}
            options={{
                clientId: paypalKey,
                vault:true
            }}
            style={{
                shape: 'rect',
                color: 'blue',
                layout: 'horizontal',
                label: 'subscribe',
            }}
        />
    );
};

export default MembershipBtn;