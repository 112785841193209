import styles from './Loading.module.css';

const Loading = () => {
    return (
        <section id={'loader'}>
            <div className={styles.smoke}>
                <ul>
                    <li>P.</li>
                    <li>C.</li>
                    <li>S.</li>
                </ul>
            </div>
        </section>
    );
};

export default Loading;