import {Link} from 'react-router-dom';
import styles from './ProductItem.module.css'
import {useContext, useEffect} from "react";
import {GlobalState} from "../../store/Globals";
import 'react-toastify/dist/ReactToastify.css';
import {FaHeart} from "react-icons/fa";

const ProductItem = ({product, related, displayMessage, cart, incrementQty, decrementQty, removeItem}) => {
    const state = useContext(GlobalState)
    const [favorites] = state.userApi.favorites

    useEffect(() => {
    }, [product, favorites])



    // Add to users cart
    const addToCart = state.userApi.addToCart;
    const addToFavs = state.userApi.addToFavs;


    return (
        <>
            <article className={`${styles.productCard}`}>
                <div className={`${styles.productCardImage}`}>
                    <Link to={`/products/detail/${product.slug}`}>
                        <img alt={product.name}
                             src={product.photoUrl === undefined ? product.images.url : product.photoUrl}/>
                    </Link>
                </div>
                <div className={`${styles.productCardName}`}>
                    <Link to={`/products/detail/${product.slug}`}>
                        <p>{product.name}</p>
                    </Link>
                </div>
                <div className={`${styles.productCardPrices} d-flex justify-content-between align-content-between`}>
                    {!cart && (
                        <>
                            {/*<p onClick={displayMessage}><FaHeart/></p>*/}
                            <p className={favorites.some(
                                fav => fav._id === product._id
                            ) ? 'favorite' : ''} onClick={() => addToFavs(product)} ><FaHeart/></p>
                            {!related && (
                                <p className={`text-center`}>
                                    {product.name}
                                </p>
                            )}
                            {related && (
                                <p>{product.name}</p>
                            )}
                            <p onClick={() => addToCart(product)}><i><img src="../images/pcs_cart.png" alt=""/></i></p>
                        </>
                    )}
                    {cart && (
                        <div className={'amount d-flex'}>
                            <button onClick={() => decrementQty(product._id)}
                                    className={'btn btn-sm btn-dark mr-2 my-2'}> -
                            </button>
                            {product.quantity}
                            <button onClick={() => incrementQty(product._id)}
                                    className={'btn btn-sm btn-dark mx-2 my-2'}> +
                            </button>
                            <button onClick={() => removeItem(product._id)} className="remove btn btn-sm btn-danger">X
                            </button>
                        </div>
                    )}
                </div>
            </article>
        </>
    );
};

export default ProductItem;


// const deleteProduct = async () => {
//     confirmAlert({
//         title: `Confirm delete product`,
//         message: `Are you sure you want to delete this category?`,
//         buttons: [
//             {
//                 label: 'Yes',
//                 onClick: async () => {
//                     try {
//                         const destroyImage = axios.post(`/upload/delete`, {public_id: product.images.public_id}, {
//                             headers: {
//                                 Authorization: token
//                             }
//                         });
//
//                         const deleteProduct = await axios.delete(`/products/${product._id}`, { // Convert to standalone function
//                             headers: {
//                                 Authorization: token
//                             }
//                         })
//                         await destroyImage
//                         await deleteProduct
//                         setCallback(!callback)
//                         toast(`${product.name} deleted`)
//                     } catch (err) {
//                         toast.error(err.response.data.msg)
//                     }
//
//                 }
//             },
//             {
//                 label: 'No',
//                 onClick: () => null
//             }
//         ]
//     })
// }