import {GlobalState} from "../../store/Globals";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {confirmAlert} from 'react-confirm-alert'; // Import
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper/core';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ProductItem from "../Products/ProductItem";


// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Pagination]);
const CartItems = ({isLoggedIn}) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userApi.cart;
    const [user] = state.userApi.user;
    const [token] = state.token
    const [total, setTotal] = useState(0)


    useEffect(() => {


        // Get user cart total
        const getTotal = () => {

            const total = cart.reduce((prev, item) => {
                return prev + (item.price * item.quantity)
            }, 0b0)
            setTotal(total)
        }
        getTotal()

    }, [total, cart, user.membership])

    // Update user cart
    const addToCart = async () => {
        await axios.patch(('/api/user/addcart'), {cart}, {
            headers: {
                Authorization: token
            }
        })
    }

    // Increment cart qty
    const incrementQty = async (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity += 1
            }
        })
        setCart([...cart])
        await addToCart()
    }

    // Decrement cart qty
    const decrementQty = async (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity === 1 ? item.quantity = 1 : item.quantity -= 1
            }
        })
        setCart([...cart])
        await addToCart()
    }

    // Remove item from cart
    const removeItem = async (id) => {
        confirmAlert({
            title: `Confirm remove product`,
            message: `Are you sure you want to remove this product?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        cart.forEach((item, index) => {
                            if (item._id === id) {
                                cart.splice(index, 1)
                            }
                        })
                        setCart([...cart])
                        await addToCart()
                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    return (
        <>

            <section className={`related-products`}>
                <Swiper
                    spaceBetween={20}
                    pagination={true}
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            width: 640,
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                    }}
                >
                    {cart.map(product => (
                        <>
                            <SwiperSlide key={product._id}>
                                <ProductItem related={true} cart={true} key={product._id} incrementQty={incrementQty}
                                             decrementQty={decrementQty} product={product} removeItem={removeItem}/>
                            </SwiperSlide>

                        </>
                    ))}
                </Swiper>
            </section>

            <div className="total d-flex justify-content-center align-items-center flex-column">
                {user.membership ? (
                    <h4 className={`px-2 text-success`}>Shipping: Free Shipping</h4>
                ) : <h4 className={`px-2`}>Shipping: $8</h4>}
                {!user.membership && (
                    <h4 className={`px-2`}>Your Total: <span className={`text-danger`}>${total + 8}</span></h4>
                )}
                <h4 className={`px-2`}>Membership Price: <span className={`text-success`}>${total}</span></h4>
            </div>
        </>
    );
};

export default CartItems;