import Layout from "../../components/Layouts/Layout";
import {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import {GlobalState} from "../../store/Globals";
import Loading from "../../components/Loading/Loading";
import {useHistory} from 'react-router-dom'
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
import ProductItem from "../../components/Products/ProductItem";

SwiperCore.use([Pagination]);


const OrderDetails = () => {
    const state = useContext(GlobalState);
    const [orders] = state.userApi.orders;
    const [orderDetails, setOrderDetails] = useState([]);
    const [user] = state.userApi.user;
    const [token] = state.token;
    const [orderStatus, setOrderStatus] = useState('');
    const [shippingCompany, setShippingCompany] = useState('')
    const [shippingLabel, setShippingLabel] = useState('')

    const params = useParams()
    const history = useHistory()

    useEffect(() => {
        if (params.id) {
            orders.forEach(order => {
                if (order._id === params.id) setOrderDetails(order)
            })
        }
        if (orderDetails) setOrderStatus(orderDetails.status)
    }, [params.id, orders, orderDetails])

    const updateOrderStatus = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(('/orders/status'), {orderStatus, orderDetails, shippingLabel, shippingCompany}, {
                headers: {
                    Authorization: token
                }
            })
            toast.error('Status Updated')
            setOrderStatus(orderDetails.status)
            if (typeof window !== 'undefined') {
                window.location.href = history.location.pathname;
            }


        } catch (err) {
            toast.error(err.message)
        }
    }

    const openInNewTab = () => {

        const newWindow = window.open(orderDetails.shippingLabel, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null

    }

    const updateStatus = (e) => {
        setOrderStatus(e.target.value)
    }

    if (orderDetails.length === 0) return <Loading/>
    return (
        <Layout>
            <section>
                <button className={`btn btn-sm btn-outline-dark`} onClick={history.goBack}>Back to Dashboard</button>
            </section>
            <ToastContainer/>
            <section>
                <h2 className={'text-center'}>
                    Order #{orderDetails.payerInfo.orderId} Details
                </h2>
            </section>

            <section>
                <div className={`d-flex justify-content-between align-items-center flex-column flex-md-row`}>
                    <p className={`mb-0`}>Order Date:</p>
                    <p>{new Date(orderDetails.createdAt).toLocaleDateString('en-us')}</p>

                    <p className={`mb-0`}>Total: </p>
                    <p>${orderDetails.payerInfo.total}</p>

                    <p className={`mb-0`}>Shipping Address:</p>
                    <p> {orderDetails.address}</p>

                    {orderDetails.customerNotes.length > 1 && (
                        <>
                            <p className={`mb-0`}>Customer Notes:</p> <p> {orderDetails.notes}</p>
                            <p>Note to P.C.S: {orderDetails.customerNotes}</p>
                        </>
                    )}
                    <p>Status: {orderDetails.status.charAt(0).toUpperCase()+ orderDetails.status.slice(1)}</p>
                    {orderDetails.status === 'shipped' && (
                        <button onClick={() => openInNewTab()} className={`btn btn-outline-dark btn-sm`}>Track
                            Via: {orderDetails.shippingCompany}</button>
                    )}
                </div>
                <section>
                    <Swiper
                        spaceBetween={20}
                        pagination={true}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                        }}
                    >

                        {orderDetails.cart.map((product) => (
                            <SwiperSlide key={product._id}>
                                <ProductItem related={true} key={product._id} product={product}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>
            </section>


            {user.role === 'admin' && (
                <>
                    <section className={`text-center`}>
                        Set Order Status
                        <form onSubmit={updateOrderStatus}>
                            <select name="status"
                                    value={orderDetails.status === orderStatus ? orderDetails.status : orderStatus}
                                    onChange={updateStatus} id="orderStatus">
                                <option value={'new'}>New</option>
                                <option value={'processing'}>Processing</option>
                                <option value={'shipped'}>Shipped</option>
                            </select>
                            {orderStatus === 'shipped' && (
                                <>
                                    <input type="text" value={orderDetails.shippingCompany}
                                           onChange={e => setShippingCompany(e.target.value)}
                                           placeholder={'Shipping Company'}/>
                                    <input type="text" value={orderDetails.shippingLabel}
                                           onChange={e => setShippingLabel(e.target.value)}
                                           placeholder={'Shipping Label'}/>
                                </>
                            )}
                            <button
                                className={`btn ${orderStatus === orderDetails.status ? 'btn-dark' : 'btn-success'} ml-3`}
                                type={'submit'} disabled={orderStatus === orderDetails.status}>Update Status
                            </button>
                        </form>
                    </section>
                </>
            )}
        </Layout>
    );
};

export default OrderDetails;