import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom'
import {useState} from "react";
import Layout from "../../components/Layouts/Layout";
import Loading from "../../components/Loading/Loading";
import axios from "axios";

export default function LoginPage({history}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const loginUser = async e => {
        e.preventDefault()
        setLoading(true)
        const user = {email, password}
        try {
            await axios.post('/api/user/login', {...user})
            localStorage.setItem('pcs-login', true)
            setLoading(false)
            if (typeof window !== 'undefined') {
                window.location.href = "/";
            }
        } catch (err) {
            toast.error(err.response.data.msg)
        }

    }
if (loading) {
    return (
        <Layout>
            <Loading/>
        </Layout>
    )
}
    return (
        <Layout title={'Purity Cottage Skin Care | Login'}>
            <section className={`container`}>
                <h1 className={`text-center`}>Login Page</h1>
            </section>
            <ToastContainer/>

            <section className={`container`}>
                <form className={'col-lg-6 col-md-8 col-sm-10 mx-auto'} onSubmit={loginUser}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1"
                               aria-describedby="emailHelp" placeholder="Enter email"
                               onChange={e => setEmail(e.target.value)} value={email}/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                            anyone else.
                        </small>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1"
                               placeholder="Password" onChange={e => setPassword(e.target.value)} value={password}/>
                    </div>
                    <p className={'mt-2'}>
                        Don't have an account? <Link to={'/user/register'}><span
                        className={'text-danger'}>Register Here</span></Link>
                    </p>
                    <div className={'my-4'}>
                        <button type="submit" className="btn btn-outline-dark">Login</button>
                    </div>
                </form>
            </section>
        </Layout>
    )
}