import {useContext} from "react";
import {GlobalState} from "../../store/Globals";

const LoadMore = ({amount}) => {
    const state = useContext(GlobalState)
    const [page, setPage] = state.productsApi.page;
    const [results] = state.productsApi.searchResults;

    return (
        <div style={{width: '25%', margin: '0 auto'}}>
            {
                results < page * amount ? ""
                    : <button className={`btn btn-block btn-outline-dark text-center`} onClick={() => setPage(page + 1)}>Load More</button>// Change 6 in ProductsApi.js to match
            }
        </div>
    );
};

export default LoadMore;