import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import Loading from "../Loading/Loading";
import {toast} from "react-toastify";
import axios from "axios";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useHistory} from 'react-router-dom'
import CreateServiceEditor from "./CreateServiceEditor";
import {convertToRaw} from "draft-js";

const CreateService = ({service, edit}) => {
    const history = useHistory()
    const state = useContext(GlobalState)
    const [isAdmin] = state.userApi.isAdmin
    const [token] = state.token;
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('');
    const [time, setTime] = useState('');
    const [notes, setNotes] = useState('')
    const [bookingLink, setBookingLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [serviceOrder, setServiceOrder] = useState('');
    const [isEdit, setIsEdit] = useState(edit)
    const [callback, setCallback] = state.servicesApi.callback
    const [richText, setRichText] = useState('')

    useEffect(() => {
        setLoading(true)
        if (!isAdmin) history.push('/')
        if (!token) setLoading(true)
        if (isEdit) {
            setName(service.name);
            setDescription(service.description);
            setCategory(service.category);
            setTime(service.time)
            setNotes(service.notes)
            setBookingLink(service.bookingLink)
            setPrice(service.price);
            setServiceOrder(service.serviceOrder)
        }
        setLoading(false)

    }, [history, token, isAdmin, isEdit, service.brand, service.bookingLink, service.name, service.description, service.time, service.category, service.notes, service.price, service.quantity, service.images, service.size, service.serviceOrder])

    // Create new services or edit services is isEdit is true
    const createService = async e => {
        e.preventDefault();

        try {
            if (!isAdmin) return toast.warning('Must be admin to preform this task');
            setLoading(true)
            if (isEdit) {
                await axios.put(`/api/services/${service._id}`, {
                    name,
                    description,
                    category,
                    time,
                    notes,
                    bookingLink,
                    price,
                    serviceOrder
                    // richText:JSON.stringify(convertToRaw(richText))
                }, {
                    headers: {
                        Authorization: token
                    }
                })
            } else {
                await axios.post('/api/services', {
                        name, description, notes, price, time, serviceOrder,
                        category, bookingLink, richText: JSON.stringify(convertToRaw(richText))
                    },
                    {
                        headers: {
                            Authorization: token
                        }
                    })
            }
            setName('')
            setDescription('')
            setCategory('')
            setTime('')
            setNotes('')
            setPrice('')
            setBookingLink('')
            setServiceOrder('')
            setIsEdit(false)
            setLoading(false)
            setCallback(!callback)

            if (typeof window !== 'undefined') {
                window.location.href = "/services";
            }
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }


    // Confirm edit cancel
    const editCancel = () => {
        confirmAlert({
            title: `Confirm edit cancel`,
            message: `Do you want to quit editing service ${name}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setName('')
                        setDescription('')
                        setCategory('')
                        setTime('')
                        setBookingLink('')
                        setPrice('')
                        setNotes('')
                        setServiceOrder('')
                        setIsEdit(false)
                        // setCallback(!callback)
                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    return (
        <>
            {loading && <Loading/>}
            <form onSubmit={createService}>

                <div className="form-group">
                    <label htmlFor={'name'}>Service Name</label>
                    <input type="text" value={name} className={`form-control`} onChange={e => setName(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label htmlFor="brand">Category</label>
                    <select value={category} className={`form-control`} name={'category'}
                            onChange={e => setCategory(e.target.value)}>
                        <option value="#">Choose Option</option>
                        <option value="facial">Facial</option>
                        <option value="advanced">Advanced Treatment</option>
                        <option value="waxing">Waxing</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea className={`form-control`} id="" cols="30"
                              rows="10" onChange={e => setDescription(e.target.value)} value={description}
                              name={'description'}> </textarea>
                </div>

                <div className="form-group">
                    <label htmlFor="time">Time</label>
                    <input type="text" className={`form-control`} onChange={e => setTime(e.target.value)} name={'time'}
                           value={time}/>
                    <small>Comma Separated</small>
                </div>

                <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input type="text" className={`form-control`} onChange={e => setPrice(e.target.value)}
                           name={'price'} value={price}/>
                </div>

                <div className="form-group">
                    <label htmlFor="price">Service Order</label>
                    <input type="number" className={`form-control`} onChange={e => setServiceOrder(e.target.value)}
                           name={'serviceOrder'} value={serviceOrder}/>
                </div>

                <div className={`form-group`}>
                    <label htmlFor="{'bookingLink'}">Booking Link(s)</label>
                    <textarea id="" cols="30" className={`form-control`}
                              rows="4" onChange={e => setBookingLink(e.target.value)} value={bookingLink}
                              name={'booking'}> </textarea>
                    <small>Comma Separated</small>
                </div>


                <CreateServiceEditor richText={setRichText}/>

                <button
                    className={'btn btn-outline-success btn-sm'}>{isEdit ? 'Update Service' : 'Create Service'}</button>

            </form>
            {isEdit && (
                <button className={'btn btn-outline-danger btn-sm'} onClick={() => editCancel()}>Cancel</button>
            )}
        </>
    );
};

export default CreateService;