import LoadMore from "../Products/LoadMore";

const ManageProducts = ({products}) => {
    return (
        <>
            {products.map(product => (
                <p>{product.name}</p>
            ))}
            <LoadMore amount={6}/>
        </>
    );
};

export default ManageProducts;