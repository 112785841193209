import Layout from "../components/Layouts/Layout";
import ServiceTypeCards from "../components/Services/ServiceTypeCards";

const ServicesPage = () => {
    return (
        <Layout title={'Purity Cottage Skincare | Services'}
                description={'Purity Cottage Skincare has a full line of skincare services. From pampering facials just to give your skin that glow to microdermabrasion for those more problematic' +
                'skin issues' }>
            <section className={'text-center'}>
                <h1>Skincare Services</h1>
            </section>

            <section>
                <ServiceTypeCards/>
            </section>
        </Layout>
    );
};

export default ServicesPage;