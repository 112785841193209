import {Helmet} from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactInfo from "../Home/ContactInfo";
import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";

const Layout = ({title, description, keywords, children}) => {
    const state = useContext(GlobalState)
    const [user] = state.userApi.user
    const [darkMode, setDarkMode] = useState(false)
    useEffect(() => {
        setDarkMode(user.darkMode)
    }, [user.darkMode])

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name={'description'} content={description}/>
                <meta name={'keywords'} content={keywords}/>
            </Helmet>
            <div data-theme={darkMode ? "dark" : "light"}>
                <ContactInfo/>
                <Navbar/>
                <div className="container">
                    {children}
                </div>
                <Footer/>
            </div>
        </>
    )
};

Layout.defaultProps = {
    title: 'Purity Cottage Skincare',
    description: 'PurityCottage.com: Purity Cottage Skincare is a Home based / mobile skincare services that brings the enjoyment of spa services into the comfort of your own Home.',
    keywords: 'spa, mobile spa, facial, facial cleanse, esthetician, aesthetician, esthetics, aesthetics, mobile, destination, mobile destination, Spa in Atlanta Ga,\n' +
        'Spa in Atlanta Georgia, Spa in Lawrenceville Georgia, Lawrenceville, Atlanta, Gwinnett Spa, Spa in Lawrenceville GA, Black Owned, Women Lead, Skin consult, Skin consultation, acne facials,\n' +
        'facials for oily skin'
}

export default Layout;