import {Link} from 'react-router-dom'
import styles from './Footer.module.css'
const Footer = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <footer className="footer pt-5">
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-md-3 col-sm-12 text-center">
                        <Link to="/" className="">
                            <h3 className=" text-black">
                                Purity Cottage <span className={`${styles.pcTextGrey} ${styles.skincareSmall}`}>Skincare</span></h3>
                        </Link>
                    </div>
                    <div className="col-md-3 col-sm-12 text-center">
                        <p className="h5">Location</p>
                        <address>11300 Medlock Bridge Rd. <br/>
                            Duluth Georgia, 30096
                            Suite 320
                        <p><small>Located inside Salon Lofts</small></p>
                        </address>
                        <p className="h5">Hours</p>
                        <p>Wednesday thru Saturday: <br/> 9:00am - 7:00pm</p>
                    </div>
                    <div className="col-md-3 col-sm-12 text-center">
                        <p className="h5">Contact</p>
                        <p className="">
                            <Link className="" to="tel:8487776849">848.777.6849</Link>
                        </p>
                        <p className="h5">Social</p>
                        <p className="social"
                              onClick={() => openInNewTab(`https:www.facebook.com/puritycottage`)}
                              aria-label="Open Facebook page for Purity Cottage Skin Care"> Follow on Facebook</p>
                        <p onClick={() => openInNewTab(`https:www.instagram.com/puritycottageskincare`)}
                              aria-label="Open Instagram for Purity Cottage Skincare" className="social">Follow on Instagram</p>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-12 pt-2">*/}
                {/*        <p className="text-center">Site built and maintained by <Link to="http://www.codecraftersacad.com"*/}
                {/*                                                                  target="_blank"*/}
                {/*                                                                  aria-label="Open link to Code Crafters Acad who built Purity Cottage Skincare website">Code*/}
                {/*            Crafters Acad</Link></p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
};

export default Footer;