import {useContext} from "react";
import {GlobalState} from "../../store/Globals";

const Search = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesApi.categories;
    const [brands] = state.brandsApi.brands;
    const [category, setCategory] = state.productsApi.category
    const [brand, setBrand] = state.productsApi.brand
    const [searchTerm, setSearchTerm] = state.productsApi.searchTerm

    const handleBrandUpdate = async e => {
        setSearchTerm('')
        setBrand(e.target.value)
    }
    const handleCategoryUpdate = async e => {
        setSearchTerm('')
        setCategory(e.target.value)
    }

    return (
        <>

            <div className={`text-left mt-4`}>
                <form>
                    {/*<div className="row">*/}
                        {/*<div className="col-lg-12 col-md-12 col-sm-12 col-12">*/}
                             {/*Fix issue with searching product by name makes it not show related products.*/}
                            {/*<div className={`form-group`}>*/}
                            {/*    <label htmlFor="search">Search:</label>*/}
                            {/*    <input type="text" className={`form-control`} value={searchTerm}*/}
                            {/*           placeholder={'Search For Product'}*/}
                            {/*           onChange={e => setSearchTerm(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className="sort form-group">
                                <label htmlFor="sort">Sort By:</label>
                                <select name="sort" value={category} className={`form-control`} id="sort"
                                        onChange={handleCategoryUpdate}>
                                    <option value="">Newest</option>
                                    <option value="sort=oldest">Oldest</option>
                                    <option value="sort=-sold">Best Selling</option>
                                    <option value="sort=-price">Price: Highest -> Lowest</option>
                                    <option value="sort=+price">Price: Lowest -> Highest</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                            <div className={`form-group`}>
                                <label htmlFor="category">Category:</label>
                                <select name={'category'} className={`form-control`} value={category}
                                        id={'category'}
                                        onChange={handleCategoryUpdate}
                                        aria-label="Select product category">
                                    <option value="">Any</option>
                                    {categories && categories.map(category => (
                                        <option key={category._id}
                                                value={`category=${category.name}`}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-4">
                            <div className={`form-group`}>
                                <label htmlFor="category">Brand:</label>
                                <select name={'brand'} value={brand} id={'brand'} onChange={handleBrandUpdate}
                                        className="form-select form-control" aria-label="Select product brand">
                                    <option value="">Any</option>
                                    {brands && brands.map(brand => (
                                        <option key={brand._id} value={`brand=${brand.name}`}>{brand.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Search;