import {convertFromRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {makeStyles} from "@material-ui/core";

const ServiceDetailsCard = ({service, onClose}) => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #cb987b',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1, 2, 1),
            [theme.breakpoints.down("xs")]: {
                width: '80%'
            },
        },
        close: {
            margin: theme.spacing(1),
            borderRadius: '5px',
            boxShadow: theme.shadows[2],
            width: '20px',
            backgroundColor: '#cb987b',
            color: '#fff',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            cursor: 'pointer',
            float: 'right'
        }
    }));

    function myBlockStyleFn(contentBlock) {
        const type = contentBlock.getType();
        if (type === '') {
            return 'superFancyBlockquote';
        }
    }

    const classes = useStyles()
    let text = JSON.parse(service.richText)
    const contentState = convertFromRaw(text);
    const editorState = EditorState.createWithContent(contentState);
    return (
        <div className={classes.paper}>
            <p className={classes.close} onClick={onClose}>X</p>
            <Editor blockStyleFn={myBlockStyleFn} editorClassName={'service-details'} editorState={editorState}
                    readOnly={true} toolbarHidden/>
        </div>
    );
};

export default ServiceDetailsCard;