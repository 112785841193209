import {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import {GlobalState} from "../../store/Globals";


const InformationButtons = ({role, orders, showOrders, showWishlist, showInfo}) => {
    const state = useContext(GlobalState);
    const [allOrders] = state.userApi.orders;
    const [newOrders, setNewOrders] = useState(0)
    const [processingOrders, setProcessingOrders] = useState(0)

    const countNewOrders = allOrders.filter(order => {
        return order.status === 'new'
    })

    const countProcessingOrders = allOrders.filter(order => {
        return order.status === 'processing'
    })


    useEffect(() => {
        if (allOrders.length > 0) {
            setNewOrders(countNewOrders.length)
            setProcessingOrders(countProcessingOrders.length)
        }
    }, [newOrders, countNewOrders.length, countProcessingOrders.length, allOrders.length])

    return (
        <>
            <section className={'text-center'}>
                {role === 'user' && (
                    <div className={`d-flex justify-content-center`}>
                        {orders.length > 0 && (
                            <button className={`btn btn-sm btn-outline-dark mr-2`}
                                    onClick={() => showOrders()}>View My Orders</button>
                        )}
                        <button className={`btn btn-sm btn-outline-dark mx-2`}
                                onClick={() => showWishlist()}>View My Wishlist
                        </button>
                        <button className={`btn btn-sm btn-outline-dark mx-2`}
                                onClick={() => showInfo()}>View My Information
                        </button>
                    </div>
                )}

                {role === 'admin' && (
                    <div className={`my-3`}>
                        {orders.length > 0 && (
                            <button
                                className={`btn btn-sm mr-2 ${newOrders > 0 && 'btn-success'} ${newOrders === 0 && processingOrders > 0 && 'btn-outline-danger'}`}
                                onClick={() => showOrders()}>Orders
                                New: {newOrders} Processing: {countProcessingOrders.length}</button>
                        )}
                        <button className={`btn btn-sm btn-outline-dark mx-2`}
                                onClick={() => showInfo()}>View My Information
                        </button>
                        <Link to={'/admin/products'} className={`btn btn-sm btn-outline-dark`}>Manage Products</Link>
                        <Link to={'/admin/services'} className={`btn btn-sm btn-outline-dark`}>Manage Services</Link>
                        <Link to={'/admin/categories'} className={`btn btn-sm btn-outline-dark`}>Manage Categories</Link>
                        <Link to={'/admin/brands'} className={`btn btn-sm btn-outline-dark`}>Manage Brands</Link>
                    </div>
                )}
            </section>
        </>
    );
};

export default InformationButtons;