import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import moment from "moment";
import Avatar from "../User/avatar";

const UserInfo = ({user}) => {
    const [name, setName] = useState(user.name)
    const [phone, setPhone] = useState(user.phone || '(123)-555-1010')
    const [email, setEmail] = useState(user.email)
    const [address, setAddress] = useState(user.address)
    const [edit, setEdit] = useState(false);
    const state = useContext(GlobalState);
    const [token] = state.token;
    const [callback, setCallback] = state.userApi.callback
    const [theme, setTheme] = useState(false)
    const [emailValid, setEmailValid] = useState(false);
    const [images, setImages] = useState(false)
    const [loading, setLoading] = useState(false)


    const editInfo = () => {
        setEdit(!edit)
        setName(user.name);
        setPhone(user.phone);
        setEmail(user.email);
        setAddress(user.address);
        setTheme(user.darkMode)
        setEmailValid(true)
        setImages(user.avatar)
    }

    const setImage = (image) => {
        setLoading(true)
        setImages(image)
        setLoading(false)
    }

    useEffect(() => {
    }, [user.name, user.phone, user.address, user.email, user.darkMode, user.avatar])

    const editUser = async () => {
        try {
            await axios.put('/api/user/profile/update', {
                name,
                phone,
                email,
                user,
                address,
                darkMode: theme,
                avatar: images
            }, {
                headers: {
                    Authorization: token
                }
            })
            setEdit(!edit)
            setName(name)
            setEmail(email)
            setPhone(phone)
            setAddress(address)
            setCallback(!callback)
            setTheme(theme)
            setImages(images)
            setEmailValid(true)
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    const checkValidEmail = async () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            toast('Invalid Email', {
                toastId: 'invalid-email'
            })
            toast.clearWaitingQueue()
            setEmailValid(false)
        } else {
            await checkEmail()
        }

        // return re.test(String(email).toLowerCase());
    }

    const checkEmail = async () => {

        // Check if user switched to original email
        if (email === user.email) {
            setEmailValid(true)
            return
        }

        const checkEmailUsed = await axios.post('/api/user/find', {email})

        if (checkEmailUsed.status === 200) {
            toast(checkEmailUsed.data.msg, {
                toastId: 'existing-user'
            })

            toast.clearWaitingQueue()
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }
    }

    return (
        <section>
            {/* Toast Container */}
            <ToastContainer/>
            {/* End of Toast Container */}
            <h3 className={`text-center`}>My Information</h3>
            {/*<Avatar edit={edit} user={user} setImage={setImage}/>*/}
            <div className={`my-3`}>

                {user.membership === true && user.membershipEndDate.length > 0 && (
                    <div className="row d-flex justify-content-center">
                        <p className={`lead mx-2`}>
                            Membership Status: <b className={`text-success`}>Active</b>
                        </p>
                        <p className={`lead mx-2`}>Membership end date: <b
                            className={`text-success`}>{moment(user.membershipEndDate).format('MMM do yy')}</b></p>
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type={'text'} id={'name'} className={`form-control`} value={name}
                               placeholder={`Update your name`} disabled={!edit}
                               onChange={e => setName(e.target.value)}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="form-group">
                        <label htmlFor="phone">Phone:</label>
                        <input type="tel" id={'phone'} className={`form-control`} value={phone}
                               placeholder={`Update your phone`} disabled={!edit}
                               onChange={e => setPhone(e.target.value)}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input type="email" className="form-control" id="email"
                               aria-describedby="emailHelp" placeholder="Enter email"
                               disabled={!edit}
                               onChange={e => setEmail(e.target.value)} value={email} onBlur={checkValidEmail}/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with
                            anyone else.</small>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <textarea name="address" id="address" rows="3" className={`form-control`} disabled={!edit}
                                  onChange={e => setAddress(e.target.value)} value={address}/>
                    </div>
                </div>
            </div>
            {/*<div className="row">*/}
            {/*    <div className="col-12">*/}
            {/*        <FormControl component="fieldset">*/}
            {/*            <FormLabel component="legend">Preferences</FormLabel>*/}
            {/*            <FormControlLabel*/}
            {/*                control={<Switch*/}
            {/*                    checked={theme}*/}
            {/*                    disabled={edit}*/}
            {/*                    onChange={() => setTheme(!theme)}*/}
            {/*                    name="darkMode"*/}
            {/*                    inputProps={{'aria-label': 'secondary checkbox'}}*/}
            {/*                    aria-label="Dark Mode (not recommended for use)"*/}
            {/*                    label="Dark Mode (not recommended for use)"*/}
            {/*                />}*/}
            {/*             label={'Dark Mode On (Not recommended for use)'}/>*/}
            {/*        </FormControl>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <section>
                <div className={`btn-group-lg d-flex justify-content-between`}>
                    <button className={`btn btn-outline-dark`}
                            onClick={() => editInfo()}>{!edit ? 'Edit' : 'Cancel'}</button>
                    {edit && (
                        <button disabled={!emailValid || loading} className={`btn btn-outline-dark`}
                                onClick={editUser}>Save</button>
                    )}
                </div>
            </section>
        </section>
    );
};

export default UserInfo;