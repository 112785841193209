import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useContext, useEffect, useState} from "react";
import ServiceDetailsCard from "./ServiceDetailsCard";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {GlobalState} from "../../store/Globals"; // Import css
import {Link, useHistory} from 'react-router-dom'
import {confirmAlert} from "react-confirm-alert";
import axios from 'axios'
import {toast, ToastContainer} from "react-toastify";
import {Modal} from "@material-ui/core";
import {InlineWidget} from 'react-calendly'
import {
    FacebookShareButton,
    TumblrShareButton,
    TwitterShareButton
} from 'react-share';
import styles from './ServiceItem.module.css'

const ServiceItem = ({service}) => {
    const [showDetails] = useState(false);
    const [callback, setCallback] = useState(false);
    const state = useContext(GlobalState);
    const [isAdmin] = state.userApi.isAdmin;
    const [token] = state.token;
    const [open, setOpen] = useState(false);
    const [booking, setBooking] = useState(false);
    const history = useHistory();
    const [user] = state.userApi.user;
    useEffect(() => {

    }, [service])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createBooking = () => {
        setBooking(true)
    }

    const cancelBooking = () => {
        setBooking(false)
    }

    const deleteService = async () => {
        confirmAlert({
            title: `Confirm delete service`,
            message: `Are you sure you want to delete this category?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const deleteService = await axios.delete(`/api/services/${service._id}`, { // Convert to standalone function
                                headers: {
                                    Authorization: token
                                }
                            })
                            await deleteService
                            setCallback(!callback)
                            toast(`${service.name} deleted`)
                        } catch (err) {
                            toast.error(err.response.data.msg)
                        }

                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    return (
        <>
            <ToastContainer/>
            <div className={styles.cardContainer} id={`${service.slug}`}>
                <div className={styles.cardBodyContainer}>
                    <h5 className={styles.cardBodyTitle}>{service.name}</h5>
                    <p className={`${styles.cardBodyText} ${styles.cardBodyTextDescription}`}>{service.description.substring(0, 130)}...</p>
                    <div className={styles.cardBodyPricing}>
                        {service.price.map((price, index) => (
                            <div key={price + index}>
                                <p className={styles.cardBodyText}>
                                    ${price} / {service.time[index]}{service.name !== 'Dueling Suns' ? 'min' : ''}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className={styles.cardBodyBooking}>
                        {service.bookingLink.map((link, index) => (
                            <>
                                <Modal key={link} onClose={cancelBooking} open={booking}>
                                    <InlineWidget url={link} onClose={cancelBooking} prefill={{
                                        name: user.name,
                                        email: user.email,
                                        customAnswers: {
                                            a1: user.phone,
                                            a2: 'a1',
                                            a3: user.address !== null ? user.address : 'Enter Address'
                                        },
                                        location: true
                                    }}/>
                                </Modal>
                                <button key={index} className={'btn btn-outline-dark'}
                                        onClick={createBooking}>
                                    Book
                                    {/*Book {service.time[index]}*/}
                                </button>
                            </>
                        ))}
                    </div>

                    <div className={`my-4 text-center`}>
                        <button onClick={handleOpen}
                                className="btn btn-sm btn-primary card-body-btn">{!showDetails ? 'View Details' : 'Close Details'}</button>
                    </div>
                    <div className={'container text-center container'}>
                        <p className={`text-center mb-2`}>Share with friends!</p>
                        <div className={`d-flex justify-content-between`}>
                            <FacebookShareButton
                                url={`https://puritycottage.com/${window.location.pathname}#${service.slug}`}
                                quote={`Purity's ${service.name} is great for any of your skin's needs`}>
                                <Link to={'#'} className={`btn btn-sm btn-outline-dark`}>Facebook</Link>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={`https://puritycottage.com/${window.location.pathname}#${service.slug}`}
                                quote={'Check out this amazing service from Purity Cottage.'}>
                                <Link to={'#'} className={`btn btn-sm btn-outline-dark`}>Twitter</Link>
                            </TwitterShareButton>
                            <TumblrShareButton
                                url={`https://puritycottage.com/${window.location.pathname}#${service.slug}`}
                                quote={'Check out this amazing service from Purity Cottage.'}>
                                <Link to={'#'} className={`btn btn-sm btn-outline-dark`}>Tumblr</Link>
                            </TumblrShareButton>
                        </div>
                    </div>
                    <Modal open={open} onClose={handleClose}>
                        <ServiceDetailsCard service={service} onClose={handleClose}/>
                    </Modal>

                </div>
                <div className={`card-body-admin`}>
                    {
                        isAdmin && (
                            <>
                                <Link to={`/admin/service_edit/${service.slug}`}
                                      className={'btn btn-sm btn-outline-dark mx-2'}>
                                    Edit
                                </Link>
                                <button onClick={() => deleteService()}
                                        className={'btn btn-sm btn-outline-danger ml-2'}>
                                    Delete
                                </button>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default ServiceItem;




