import Layout from "../components/Layouts/Layout";
import {GlobalState} from "../store/Globals";
import {useContext} from "react";
import CartItems from "../components/Cart/CartItems";
import Checkout from "../components/Cart/Checkout";

const CartPage = ({history}) => {
    const state = useContext(GlobalState)
    const [isLoggedIn] = state.userApi.isLoggedIn;
    const [user] = state.userApi.user
    const [cart] = state.userApi.cart

    const {name} = user;

    if (cart.length === 0)
        return (
            <Layout>
                <section>
                    <h2 className={'text-center my-5'}>No items in your cart</h2>
                </section>
            </Layout>
        )

    return (
        <Layout title={'Purity Cottage Skincare | Shopping Cart'} description={`Purity Cottage Skincare shopping cart.`}>
            <section>
                <h2 className={'text-center'}>{name && name}'s Shopping Cart</h2>
            </section>

            <section>
                <CartItems isLoggedIn={isLoggedIn}/>
            </section>
            <section>
                <Checkout history={history}/>
            </section>
        </Layout>
    )
};

export default CartPage;