const Fountains = () => {
    return (
        <section className="container py-5 mb-5" id="addOns">
            <div className="text-center mb-2">
                <h2 className="">Fountains of Youth</h2>
                <p className="text-muted lead">Add these to any facial on our menu.
                    (Can not be added to Hostess program)
                </p>
            </div>

            <table className="table responsive striped">
                <thead>
                <tr>
                    <th>
                        Add-on
                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Time
                    </th>
                    <th>
                        Price
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="">Eye Treatment</td>
                    <td className="">Includes eye mask, serum, and eye roller</td>
                    <td className="">5 Minutes</td>
                    <td className="">$15</td>
                </tr>
                <tr>
                    <td className="">NuFace (micro-current)</td>
                    <td className="">Facial contouring, toning, and firming
                        skin
                    </td>
                    <td className="">15 Minutes</td>
                    <td className="">$20</td>
                </tr>
                <tr>
                    <td className="">Oxygen Spray</td>
                    <td className="">Hydrates and strengthens skin
                        elasticity
                    </td>
                    <td className="">5 Minutes</td>
                    <td className="">$15</td>
                </tr>
                <tr>
                    <td className="">Decolleté Treatment</td>
                    <td className="">Includes gold scrub and firming massage
                        oil
                    </td>
                    <td className="">10 Minutes</td>
                    <td className="">$15</td>
                </tr>
                <tr>
                    <td className="">Lip Treatment</td>
                    <td className="">Sugar scrub, serum, and lip
                        conditioner
                    </td>
                    <td className="">5 Minutes</td>
                    <td className="">$15</td>
                </tr>

                <tr>
                    <td className="">High Frequency</td>
                    <td className="">Increases oxygen to the skin while killing bacteria
                    </td>
                    <td className="">5 Minutes</td>
                    <td className="">$15</td>
                </tr>
                </tbody>
            </table>

        </section>
    );
};

export default Fountains;