import {Link} from "react-router-dom";
import {useState} from "react";

const UserOrders = ({orders}) => {
    const [pastOrders, setPastOrders] = useState(false)
    return (
        <>
            <section className={`d-flex justify-content-center`}>
                <button className={`btn btn-outline-dark`} onClick={() => setPastOrders(!pastOrders)}>View Shipped Orders</button>
            </section>
            <section>
                {orders.length > 0 && (
                    <section>
                        <table className={`table`}>
                            <thead>
                            <tr>
                                <th scope={`col`}>Order Id</th>
                                <th scope={`col`}>Purchase Date</th>
                                <th scope={`col`}>Status</th>
                                <th scope={`col`}>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map(order => {
                                return (order.payerInfo && (
                                    order.status === 'new' && (
                                        <tr key={order.payerInfo.orderId}>
                                            <td>{order.payerInfo.orderId}</td>
                                            <td>{new Date(order.createdAt).toLocaleDateString('en-us')}</td>
                                            <td>{order.status}</td>
                                            <td><Link to={`/order/${order._id}`}>View</Link></td>
                                        </tr>
                                    )
                                ))
                            })}
                            </tbody>
                        </table>
                    </section>
                )}
            </section>
            {pastOrders && (
                <section>

                    <h3 className={`text-center`}>Shipped Orders</h3>
                    <table className={`table`}>
                        <thead>
                        <tr>
                            <th scope={`col`}>Order Id</th>
                            <th scope={`col`}>Purchase Date</th>
                            <th scope={`col`}>Status</th>
                            <th scope={`col`}>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map(order => {
                            return (order.payerInfo && (
                                order.status !== 'new' && (
                                    <tr key={order.payerInfo.orderId}>
                                        <td>{order.payerInfo.orderId}</td>
                                        <td>{new Date(order.createdAt).toLocaleDateString('en-us')}</td>
                                        <td>{order.status}</td>
                                        <td><Link to={`/order/${order._id}`}>View</Link></td>
                                    </tr>
                                )
                            ))
                        })}
                        </tbody>
                    </table>
                </section>
            )}
        </>
    );
};

export default UserOrders;