import {createContext, useState, useEffect} from "react";
import UserApi from "../api/UserAPI";
import ServicesApi from "../api/ServicesApi";
import ProductsApi from "../api/ProductsAPI";
import CategoriesApi from "../api/CategoriesApi";
import BrandsApi from "../api/BrandsApi";
import axios from "axios";

export const GlobalState = createContext()

export const DataProvider = ({children}) => {
    const [token, setToken] = useState(false);
    useEffect(() => {
        const loginCheck = localStorage.getItem('pcs-login')
        if (loginCheck) {
            const refreshToken = async () => {
                const res = await axios.get('/api/user/refresh_token')
                setToken(res.data.accessToken)
                setTimeout(() => {
                    refreshToken()
                }, 10 * 60 * 1000)
            }
            refreshToken()
        }
    }, [token])

    const state = {
        token: [token, setToken],
        productsApi: ProductsApi(),
        userApi: UserApi(token),
        categoriesApi: CategoriesApi(),
        brandsApi: BrandsApi(),
        servicesApi: ServicesApi()
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}