import {useContext} from "react";
import {GlobalState} from "../../store/Globals";

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
import ProductItem from "../Products/ProductItem";

SwiperCore.use([Pagination]);

const UserWishlist = ({wishlist}) => {
    const state = useContext(GlobalState)
    const [favorites] = state.userApi.favorites
    return (

        <section className={`related-products`}>
            <h3>My Wishlist</h3>

            <Swiper
                spaceBetween={20}
                pagination={true}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }}
            >
                {favorites.map((product) => (
                    <SwiperSlide key={product._id}>
                        <ProductItem related={false} key={product._id} product={product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default UserWishlist;