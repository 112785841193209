import {useContext, useEffect} from "react";
import {GlobalState} from "../store/Globals";
import Layout from "../components/Layouts/Layout";
import ProductItem from "../components/Products/ProductItem";
import styles from '../components/Products/ProductItem.module.css';
import Search from "../components/Search/Search";
import LoadMore from "../components/Products/LoadMore";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ProductsPage = () => {

    const state = useContext(GlobalState)
    const [products] = state.productsApi.products;
    const [callback, setCallback] = state.productsApi.callback

    const displayMessage = () => {
        toast.error('Adding to favs is currently in development', {
            toastId: 'favs'
        })
        toast.clearWaitingQueue()
    }

    useEffect(() => {

    }, [callback, products])

    if (!products) {
        toast('No products matching your search', {
            toastId: 'No Products'
        })
        toast.clearWaitingQueue();
    }

    if (products.length === 0) {
        return (
            <Layout title={'Purity Cottage Skincare | Products and skincare accessories'}
                    description={'Shop Purity Cottage Skincare\'s full line of skincare Products and accessories.'}
                    keywords={'spa, products, Circadia, skin care'}>
                <section className={'text-center'}>
                    <h1>Shop Purity Skincare</h1>
                    <Search/>
                </section>
                <section>
                <h3 className={`h1 text-center`}>No Products</h3>
                </section>
            </Layout>
        )
    }


    return (
        <>
            <ToastContainer/>
            <Layout title={'Purity Cottage Skincare | Products and skincare accessories'}
                    description={'Shop Purity Cottage Skincare\'s full line of skincare Products and accessories.'}
                    keywords={'spa, products, Circadia, skin care'}>
                <section className={'text-center'}>
                    <h1>Shop Purity Skincare</h1>
                    <Search/>
                </section>

                <main className={`container ${styles.main} ${styles.bdGrid}`}>
                    {products.map(product => (
                        <ProductItem key={product._id} displayMessage={displayMessage} product={product}
                                     callback={callback}
                                     setCallback={setCallback}/>
                    ))}
                </main>

                <div className={'my-3 text-center'}>
                    <LoadMore amount={6}/>
                </div>
            </Layout>
        </>
    );
};

export default ProductsPage;