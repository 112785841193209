import {useEffect, useState} from "react";
import axios from "axios";

const ServicesApi = () => {
    const [services, setServices] = useState([])
    const [callback, setCallback] = useState(false)
    const getServices = async () => {
        const res = await axios.get('/api/services')
        setServices(res.data.services)
    }

    useEffect(() => {
        getServices()
    }, [callback])


    return {
        services: [services, setServices],
        callback: [callback, setCallback]

    }
};

export default ServicesApi;