import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import Loading from "../Loading/Loading";
import {toast} from "react-toastify";
import axios from "axios";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useHistory} from 'react-router-dom'

const CreateProduct = ({product, edit}) => {
    const history = useHistory()
    const state = useContext(GlobalState)
    const [isAdmin] = state.userApi.isAdmin
    const [token] = state.token;
    const [brands] = state.brandsApi.brands;
    const [categories] = state.categoriesApi.categories;
    const [images, setImages] = useState(false);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState('')
    const [brand, setBrand] = useState('')
    const [size, setSize] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0);
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(edit)
    const [callback, setCallback] = state.productsApi.callback

    useEffect(() => {
        if (!isAdmin) history.push('/')
        if (!token) setLoading(true)
        if (isEdit) {
            setName(product.name);
            setDescription(product.description);
            setCategory(product.category);
            setBrand(product.brand);
            setPrice(product.price);
            setQuantity(product.quantity);
            setImages(product.images)
            setSize(product.size)
        }

    }, [history, token, isAdmin, categories, brands, isEdit, product.brand, product.name, product.description, product.category, product.price, product.quantity, product.images, product.size])

    const styleUpload = {
        display: isEdit || images ? 'block' : 'none'
    }

    // Create new product or edit product is isEdit is true
    const createProduct = async e => {
        e.preventDefault();

        try {
            if (!isAdmin) return toast.warning('Must be admin to preform this task');
            if (!images) return toast.warning('Products must be created with images');
            if (isEdit) {
                await axios.put(`/api/products/${product._id}`, {
                    name,
                    description,
                    size,
                    price,
                    quantity,
                    brand,
                    category,
                    images
                }, {
                    headers: {
                        Authorization: token
                    }
                })
            } else {
                await axios.post('/api/products', {name, description, size, price, quantity, brand, category, images}, {
                    headers: {
                        Authorization: token
                    }
                })
            }
            setImages(false)
            setName('')
            setDescription('')
            setCategory('')
            setBrand('')
            setSize('')
            setQuantity(0)
            setIsEdit(false)
            setCallback(!callback)
            if (typeof window !== 'undefined') {
                window.location.href = "/products";
            }
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    // Upload image
    const uploadImage = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return toast.warning('Must be admin to preform this task')

            const file = e.target.files[0]
            if (!file) return toast('No file found');
            if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') return toast.error('Image must be of type .jpg, .jpeg, .png')
            if (file.size > (1024 * 1024) * 3) return toast.warning('File too large. File size must not exceed 3MB')

            let formData = new FormData()
            formData.append('file', file)

            setLoading(true)
            const res = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token
                }
            })
            setLoading(false)
            setImages(res.data)

        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    // Confirm edit cancel
    const editCancel = () => {
        confirmAlert({
            title: `Confirm edit cancel`,
            message: `Do you want to quit editing product ${name}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setImages(false)
                        setName('')
                        setDescription('')
                        setCategory('')
                        setBrand('')
                        setSize('')
                        setQuantity(0)
                        setIsEdit(false)
                        // setCallback(!callback)
                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    // Remove image
    const destroyImage = async () => {
        try {
            if (!isAdmin) return toast.warning('Must be admin to preform this task');
            setLoading(true)
            await axios.post('upload/delete', {public_id: images.public_id}, {
                headers: {
                    Authorization: token
                }
            })
            setLoading(false)
            setImages(false)
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    return (
        <>
            <section>
                <button className={`btn btn-sm btn-outline-dark`} onClick={history.goBack}>Back to Dashboard</button>
            </section>
            {!brands && !categories && <Loading/>}
            <div className={`upload form-group`}>
                <input type="file" className={`form-control-file`} onChange={uploadImage}/>
                {loading ? <Loading/> : (
                    <div style={styleUpload}>
                        <img src={images ? images.url : ''} width={'150px'} height={'150px'} alt=""/>
                        <span onClick={destroyImage} style={{cursor: 'pointer'}}>x</span>
                    </div>
                )}
            </div>
            <form onSubmit={createProduct}>
                <div className="form-group">
                    <label htmlFor={'name'}>Product Name</label>
                    <input type="text" className={`form-control`} value={name} onChange={e => setName(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label htmlFor="brand">Brand</label>
                    <select value={brand} onChange={e => setBrand(e.target.value)}>
                        <option value="">Choose Brand</option>
                        {brands.map(brand => (
                            <option key={brand._id} className={`form-control`} value={brand.name}>{brand.name}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="brand">Category</label>
                    <select value={category} name={'category'} onChange={e => setCategory(e.target.value)}>
                        <option value="">Choose Category</option>
                        {categories.map(category => (
                            <option key={category._id} className={`form-control`}
                                    value={category.name}>{category.name}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea id="" cols="30"
                              rows="10" onChange={e => setDescription(e.target.value)} value={description}
                              name={'description'}> className={`form-control`} </textarea>
                </div>

                <div className="form-group">
                    <label htmlFor="size">Size</label>
                    <input type="text" className={`form-control`} onChange={e => setSize(e.target.value)} name={'size'}
                           value={size}/>
                </div>

                <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input type="number" className={`form-control`} onChange={e => setPrice(e.target.value)}
                           name={'price'} value={price}/>
                </div>

                <div className="form-group">
                    <label htmlFor="quantity">Quantity</label>
                    <input type="number" className={`form-control`} onChange={e => setQuantity(e.target.value)}
                           name={'quantity'} value={quantity}/>
                </div>

                <button
                    className={'btn btn-outline-success btn-sm'}>{isEdit ? 'Update Product' : 'Create Product'}</button>

            </form>
            {isEdit && (
                <button className={'btn btn-outline-danger btn-sm'} onClick={() => editCancel()}>Cancel</button>
            )}
        </>
    );
};

export default CreateProduct;