import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import Layout from "../../components/Layouts/Layout";
import axios from "axios";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {toast, ToastContainer} from "react-toastify"; // Import css
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";


const BrandsPage = () => {
    const state = useContext(GlobalState);
    const [brands] = state.brandsApi.brands;
    const [brand, setBrand] = useState('');
    const [showCreateBrand, setShowCreateBrand] = useState(false);
    const [showAllBrands, setShowAllBrands] = useState(false);
    const [token] = state.token
    const isAdmin = state.userApi.isAdmin
    const [callBack, setCallback] = state.brandsApi.callBack
    const [editExistingBrand, setEditExistingBrand] = useState(false)
    const [id, setId] = useState('');
    const history = useHistory();

    useEffect(() => {
        if (!isAdmin) history.push('/')
    }, [history, isAdmin])

    const createNewBrand = async (e) => {
        e.preventDefault()
        try {
            if (editExistingBrand) {
                const res = await axios.put((`/brands/${id}`), {name: brand}, {
                    headers: {
                        Authorization: token
                    }
                })
                toast.success(res.data.msg)
            } else {
                const res = await axios.post(('/brands'), {name: brand}, {
                    headers: {
                        Authorization: token
                    }
                })
                toast.success(res.data.msg)
            }
            setEditExistingBrand(false)
            setBrand('')
            setCallback(!callBack)
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    const editBrand = async (id, name) => {
        setId(id);
        setBrand(name)
        setEditExistingBrand(true)
    }

    // Remove brand
    const removeItem = async (brandId) => {

        confirmAlert({
            title: `Confirm delete product`,
            message: `Are you sure you want to delete this brand?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteBrand(brandId)
                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    const deleteBrand = async (brandId) => {
        try {
            await axios.delete((`/brands/${brandId}`), {
                headers: {
                    Authorization: token
                }
            })
            toast.success(`Brand Deleted`)
            setCallback(!callBack)

        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    return (
        <Layout>
            <section>
                <button className={`btn btn-sm btn-outline-dark`} onClick={history.goBack}>Back to Dashboard</button>
            </section>
            <div className={'text-center my-3'}>
                <button className={'btn btn-sm btn-outline-dark mr-2'}
                        onClick={() => setShowCreateBrand(!showCreateBrand)}>Create Brand
                </button>
                <button className={'btn btn-sm btn-outline-dark ml-2'}
                        onClick={() => setShowAllBrands(!showAllBrands)}>Manage Brands
                </button>

                <ToastContainer/>
            </div>
            {showCreateBrand && (
                <>
                    <section>
                        <form onSubmit={createNewBrand}>
                            <label htmlFor="brand">Create New Brand</label>
                            <input type="text" value={brand} onChange={e => setBrand(e.target.value)}/>
                            <button className={'btn btn-sm btn-success'}
                                    disabled={brand.length < 1}>{!editExistingBrand ? 'Create Brand' : 'Update Brand'}
                            </button>
                        </form>
                    </section>
                </>
            )}
            <section>
                {showAllBrands && (
                    <>

                        {brands && brands.map(brand => (
                            <div key={brand._id}>
                                <p>Brand: {brand.name}</p>
                                <button className={'btn btn-sm btn-secondary'}
                                        onClick={() => editBrand(brand._id, brand.name)}>Edit
                                </button>
                                <button className={'btn btn-sm btn-danger'} onClick={() => removeItem(brand._id)}>Delete</button>
                            </div>
                        ))}
                    </>
                )}
            </section>
        </Layout>
    );
};

export default BrandsPage;