import Layout from "../components/Layouts/Layout";
import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../store/Globals";
import {Link} from 'react-router-dom'
import MembershipBtn from "../components/Layouts/MembershipBtn";
import axios from "axios";
import {toast} from "react-toastify";

const MembershipPage = () => {
    const state = useContext(GlobalState);
    const [isLoggedIn] = state.userApi.isLoggedIn;
    const [amount, setAmount] = useState(0);
    const [payInterval, setPayInterval] = useState('');
    const [planId, setPlanId] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)
    const [length, setLength] = useState(0);
    const [membershipLengthType, setMembershipLengthType] = useState('');
    const [token] = state.token;

    useEffect(() => {
        if (planId) {
            switch (planId) {
                case 'P-2XB039309D473634PMKPK56Q': {
                    setAmount(70)
                    setPayInterval('Month (3 Month Plan)')
                    setAccepted(true)
                    setShowConfirm(false)
                    setLength(0)
                    setMembershipLengthType('month')
                    break;
                }
                case 'P-5NY542026N313072AMBXE7DA': {
                    setAmount(65)
                    setPayInterval('Month (6 Month Plan)')
                    setLength(6)
                    setMembershipLengthType('year')
                    setShowConfirm(false)
                    setAccepted(true)
                    break;
                }
                case 'P-34L01546H7286060VMC4PIBY': {
                    setAmount(715)
                    setPayInterval('Yearly (Renews Yearly)')
                    setLength(1)
                    setMembershipLengthType('year')
                    setShowConfirm(true)
                    setAccepted(false)
                    break;
                }
                default: {
                    setAmount(0)
                    setPayInterval('')
                    setLength(0)
                    setMembershipLengthType('')
                    setShowConfirm(false)
                    setAccepted(false)
                    break;
                }
            }
        }
    }, [planId])


    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            'plan_id': planId,
        });
    };

    const paypalOnError = () => { // Pass (err) if you want to ever handle error handling
        if (typeof window !== 'undefined') {
            window.location.href = "/membership";
        }
    }

    const paypalOnApprove = async () => { // pass (data,detail) of payment info

        //    Set user membership to active
        try {
            await axios.put('/api/user/profile/membership', {length, membershipLengthType}, {
                headers: {
                    Authorization: token
                }
            })

            if (typeof window !== 'undefined') {
                window.location.href = "/order/thanks";
            }

        } catch (err) {
            toast.error(err.response.data.msg)
        }
    };


    return (
        <Layout title={`Purity Cottage Skincare | Membership`}
                keywords={`pcs glow club, membership, subscription, skincare, monthly`}
                description={`PurityCottage.com: Subscribe to the Purity Cottage Skincare Glow Club. Helping you maintain your glowing skin by making it affordable to take care of.`}>
            <section className="my-5 container">
                <div className="row justify-center">
                    <div className="col-md-4 col-sm-12">
                        <img
                            src="https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_350/v1623943544/PCSv4/pcs_mem_uzkphk.png"
                            alt="Membership Card" className="img-fluid"/>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <h1 className="h2 text-muted">PCS Glow Club</h1>
                        {amount > 0 && (
                            <h2 className="lead">${amount}/{payInterval}</h2>
                        )}
                        <p className="leading-relaxed mb-4">Not only will you save money but, it will also encourage you
                            to take better care of
                            your skin!</p>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        {isLoggedIn ? (
                            <>
                                <div className={`form-group`}>
                                    <select onChange={e => setPlanId(e.target.value)} className={`form-control`}
                                            name="membershipType"
                                            id="membership">
                                        <option value="">Choose a plan option</option>
                                        <option value="P-2XB039309D473634PMKPK56Q">$70/Month (3 Month Plan)</option>
                                        <option value="P-5NY542026N313072AMBXE7DA">$65/Month (6 Month Plan)</option>
                                        <option value="P-34L01546H7286060VMC4PIBY">$715/Year (Renews Yearly)</option>
                                    </select>
                                </div>
                                {isLoggedIn && planId.length > 0 && (
                                    <>
                                        {showConfirm && (
                                            <div className={`d-flex flex-row form-group`}>
                                                <label htmlFor="accept">By checking this box you agree to understanding
                                                    that
                                                    this subscription is <b>NON REFUNDABLE</b></label>
                                                <input type="checkbox" id={`accept`} className={`form-control`}
                                                       onChange={() => setAccepted(!accepted)}/>
                                            </div>
                                        )}
                                        {accepted && (
                                            <MembershipBtn
                                                amount={amount}
                                                currency={'USD'}
                                                createSubscription={paypalSubscribe}
                                                onApprove={paypalOnApprove}
                                                catchError={paypalOnError}
                                                onError={paypalOnError}
                                                onCancel={paypalOnError}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Link to={`/user/login`}
                                  className="btn-outline-primary btn">
                                Login to Purchase
                            </Link>
                        )}
                    </div>
                </div>
            </section>

            <section className="my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="text-center lead font-weight-bold">Membership Perks</p>
                            <p className="">Membership includes the 60-Minute Purity Signature Facial.
                            </p>
                            <p className="">
                                Upgrade to a Dermaplaning, Microdermabrasion, or O2Rx treatment for an additional $30,
                                or add two for $110.
                            </p>
                            <p className="">
                                Receive 10% off all online and in-store retail products.
                            </p>
                            <p className="">
                                Enjoy $10 off any Fountain of Youth add-on.
                            </p>
                            <p className="">
                                Refer a friend once a month, and they will receive their first 60-Minute Purity
                                Signature Facial for the membership price of $65, and you can enjoy a free add-on to
                                your next service.
                            </p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="text-center lead font-weight-bold">Membership Terms</p>
                            <p>The base Glow Club membership facial is the 60-Minute Purity Signature Facial may not be swapped or
                                upgraded to any other service other than those specified.</p>
                            <p>Glow Club Membership fees are billed on the same date each month you sign up
                                until the length of membership or termination.</p>
                            <p>Glow Club Membership must be active to receive any Glow Member perk.</p>
                            <p>Only one refer a add-on credit per month.</p>
                            <p><b>Yearly </b>memberships are <b>NON-REFUNDABLE for any amount.</b></p>
                            <p><b>Yearly </b>members will receive their booking links on the first of each month after the initial payment. </p>
                            <p className="text-muted"><small>Other terms and conditions may apply varying on
                                situation.</small></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default MembershipPage;