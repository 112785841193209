import {BrowserRouter as Router, Route} from "react-router-dom";
import {DataProvider} from "./store/Globals";
// Components

// Pages
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import FacialServicesPage from "./pages/services/FacialServicesPage";
import AdvancedServicesPage from "./pages/services/AdvancedServicesPage";
import WaxingServicesPage from "./pages/services/WaxingServicesPage";
import ProductsPage from "./pages/ProductsPage";
import ProductDetailsPage from "./pages/products/ProductDetailsPage";
import MembershipPage from "./pages/MembershipPage";
import GuidelinesPage from "./pages/GuidelinesPage";
import CartPage from "./pages/CartPage";
import RegisterPage from "./pages/auth/Register";
import LoginPage from "./pages/auth/Login";
import Dashboard from "./pages/user/Dashboard";
import AdminProductsPage from "./pages/admin/AdminProductsPage";
import AdminServicesPage from "./pages/admin/AdminServicePage";
import CategoriesPage from "./pages/categories/Categories";
import BrandsPage from "./pages/brands/Brands";
import OrderDetails from "./pages/order/OrderDetails";
import ProductThanks from "./pages/ProductThanks";

// import NotFoundPage from "./pages/NotFoundPage";

function App() {

    return (
        <DataProvider>
            <Router>
                {/* Home Page */}
                <Route path={'/'} exact component={HomePage}/>

                {/* Service Pages */}
                <Route path={'/services'} exact component={ServicesPage}/>
                <Route path={'/services/facial'} exact component={FacialServicesPage}/>
                <Route path={'/services/advanced'} exact component={AdvancedServicesPage}/>
                <Route path={'/services/waxing'} exact component={WaxingServicesPage}/>


                {/* Product Pages */}
                <Route path={'/products'} exact component={ProductsPage}/>
                <Route path={'/products/detail/:slug'} exact component={ProductDetailsPage}/>

                {/* Single Pages */}
                <Route path={'/membership'} exact component={MembershipPage}/>
                <Route path={'/guidelines'} exact component={GuidelinesPage}/>

                {/* Cart Page */}
                <Route path={'/cart'} exact component={CartPage}/>

                {/* User Pages */}
                <Route path={'/user/register'} exact component={RegisterPage}/>
                <Route path={'/user/login'} exact component={LoginPage}/>
                <Route path={'/user/dashboard'} exact component={Dashboard}/>

                {/* Admin Pages */}
                <Route path={'/admin/dashboard'} exact component={Dashboard}/>
                <Route path={'/admin/products'} exact component={AdminProductsPage}/>
                <Route path={'/admin/services'} exact component={AdminServicesPage}/>
                <Route path={'/admin/service_edit/:id'} exact component={AdminServicesPage}/>
                <Route path={'/admin/product_edit/:id'} exact component={AdminProductsPage}/>
                <Route path={'/admin/categories'} exact component={CategoriesPage}/>
                <Route path={'/admin/brands'} exact component={BrandsPage}/>

                {/* Order pages */}
                <Route path={'/order/:id'} exact component={OrderDetails}/>

                {/* Thank You Pages */}
                <Route path={'/order/thanks'} exact component={ProductThanks}/>
            </Router>
        </DataProvider>
    );
}

export default App;
