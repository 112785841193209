import {useEffect, useState} from "react";
import axios from "axios";

const BrandsApi = () => {
    const [brands, setBrands] = useState();
    const [callBack, setCallback] = useState(false)

    useEffect(() => {
        const getBrands = async () => {
            const res = await axios.get(`/brands`)
            setBrands(res.data)
        }
        getBrands()
    }, [callBack])

    return {
        brands: [brands, setBrands],
        callBack: [callBack, setCallback]
    }
};

export default BrandsApi;