import Layout from "../../components/Layouts/Layout";
import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import {useParams, useHistory} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import CreateService from "../../components/Admin/CreateService";
import ManageServices from "../../components/Admin/ManageServices";
import Loading from "../../components/Loading/Loading";

const AdminServicesPage = () => {
    const state = useContext(GlobalState);
    const [token] = state.token;
    const [isAdmin] = state.userApi.isAdmin;
    const [showCreateService, setShowCreateService] = useState(false);
    const [showManageServices, setShowManageServices] = useState(false);
    const [loading, setLoading] = useState(false)
    const [services] = state.servicesApi.services
    const [service, setService] = useState({});
    const [edit, setEdit] = useState(false);

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        if (!isAdmin) history.push('/')
        if (!token) setLoading(true)

        if (params.id) {
            setShowCreateService(true);
            setEdit(true)
            services.forEach(service => {
                if (service.slug === params.id) setService(service)
            })
        }
    }, [history, isAdmin, params.id, service, services, token])


    return (
        <Layout>
            <section>
                <button className={`btn btn-sm btn-outline-dark`} onClick={history.goBack}>Back to Dashboard</button>
            </section>
            <section>
                {loading && <Loading/>}
            </section>
            <section className={'text-center'}>
                <button onClick={() => setShowCreateService(!showCreateService)}
                        className={'btn btn-sm btn-outline-dark mr-3'}>Create Service
                </button>
                <button onClick={() => setShowManageServices(!showManageServices)}
                        className={'btn btn-sm btn-outline-dark ml-3'}>Manage Services
                </button>
            </section>
            <section>
                {showCreateService && (
                    <CreateService service={service} edit={edit}/>
                )}
            </section>
            <section>
                {showManageServices && (
                    <ManageServices services={services} />
                )}
            </section>
        </Layout>
    );
};

export default AdminServicesPage;