import Layout from "../../components/Layouts/Layout";
import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import UserOrders from "../../components/Dashboard/UserOrders";
import UserWishlist from "../../components/Dashboard/UserWIshlist";
import UserInfo from "../../components/Dashboard/UserInfo";
import InformationButtons from "../../components/Dashboard/InformationButtons";
import Loading from "../../components/Loading/Loading";

const Dashboard = ({history}) => {
    const state = useContext(GlobalState)
    const [orders] = state.userApi.orders
    const [user] = state.userApi.user
    const [wishlist] = useState('My wishlist')
    const [showOrders, setShowOrders] = useState(false);
    const [showWishlist, setShowWishlist] = useState(false);
    const [showInfo, setShowInfo] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    // User Info
    // const [name, setName] = useState(user.name);
    // const [address, setAddress] = useState('')
    // const [phone, setPhone] = useState('');
    // const [notifications, setNotifications] = useState(false)
    // const [membership] = useState(false)
    // const [avatar, setAvatar] = useState('')

    useEffect(() => {
        setDarkMode(user.darkMode)
    }, [user.darkMode])

    const showUserWishlist = () => {
        setShowWishlist(!showWishlist)
        setShowInfo(false)
        setShowOrders(false)
    }

    const showUserOrders = () => {
        setShowOrders(!showOrders)
        setShowInfo(false)
        setShowWishlist(false)
    }

    const showUserInfo = () => {
        setShowInfo(!showInfo)
        setShowOrders(false)
        setShowWishlist(false)
    }
    if (!user) return <Loading/>

    return (
        <Layout title={`Purity Cottage | ${user.name ? user.name.split(' ')[0] + "'s" : 'Guest'} Dashboard`}>
            <section className={'text-center'}>
                <h2 className={`text-center ${darkMode ? 'text-white' : 'text-dark'}`}>Dashboard</h2>
                <InformationButtons role={user.role} orders={orders} showOrders={showUserOrders}
                                    showWishlist={showUserWishlist} showInfo={showUserInfo}/>
            </section>
            <section>

                {showOrders && <UserOrders orders={orders}/>
                }
                {showWishlist && <UserWishlist wishlist={wishlist}/>
                }
                {showInfo && <UserInfo user={user}/>
                }
            </section>
        </Layout>
    );
};

export default Dashboard;