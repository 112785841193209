const ManageServices = ({services}) => {
    return (
        <>
            {services.map(service => (
                <p>{service.name}</p>
            ))}
        </>
    );
};

export default ManageServices;