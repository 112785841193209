import {Link} from 'react-router-dom'

const ServiceTypeCards = () => {
    return (
        <section className=" py-5 d-flex flex-row justify-content-around align-items-center flex-wrap container-fluid">
            <div className="my-3 card" style={{width: "20rem"}}>
                <img className="card-img-top" src="https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_320/v1623943216/PCSv4/facial-6-small_nvgcpg.jpg"
                     alt="Facial services" style={{height: "270px"}}/>
                <div className="card-body">
                    <h5 className="card-title text-center">Facial Services</h5>
                    <p className="text-center">Pamper your skin</p>
                    <Link to={"/services/facial"} className="btn btn-block btn-outline-primary">View Services</Link>
                </div>
            </div>

            <div className="my-3 card" style={{width: "20rem"}}>
                <img className="card-img-top" src="https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_320/v1623943414/PCSv4/PXL_20201101_204012868_gfddvy.jpg"
                     alt="Advanced treatments" style={{height: "250px"}}/>
                <div className="card-body">
                    <h5 className="card-title text-center">Advanced Treatments & Body</h5>
                    <p className=" text-center">Need something more than a pampering?</p>
                    <Link to={"/services/advanced"} className="btn btn-block btn-outline-primary">View Services</Link>
                </div>
            </div>

            <div className="my-3 card" style={{width: "20rem"}}>
                <img className="card-img-top" src="https://res.cloudinary.com/purity-cottage/image/upload/c_scale,w_320/v1623943468/PCSv4/IMG-2263_ht7hqv.jpg"
                     alt="Waxing Services"
                     style={{height: "270px"}}/>
                <div className="card-body">
                    <h5 className="card-title text-center">Waxing services</h5>
                    <p className="card-text text-center">Full-body hair removal</p>
                    <Link to={"/services/waxing"} className="btn btn-block btn-outline-primary">View Services</Link>
                </div>
            </div>
        </section>
    );
};

export default ServiceTypeCards;