import {useState, useEffect} from "react";
import axios from "axios";

const UserApi = (token) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [cart, setCart] = useState([]);
    const [darkMode, setDarkMode] = useState(false)
    const [user, setUser] = useState({})
    const [orders, setOrders] = useState([])
    const [callback, setCallback] = useState(false)
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        if (token) {
            const getUser = async () => {
                try {
                    const res = await axios.get('/api/user/profile', {
                        headers: {
                            Authorization: token
                        }
                    })
                    setIsLoggedIn(true)
                    res.data.role === 'admin' ? setIsAdmin(true) : setIsAdmin(false);
                    setUser(res.data)
                    setCart(res.data.cart)
                    setDarkMode(user.darkMode)
                    setFavorites(res.data.favorites)
                } catch (err) {
                    alert(err.response.data.msg)
                    localStorage.removeItem('pcs-login')
                }
            }
            getUser()
        }
    }, [token, callback, user.darkMode])

    useEffect(() => {
        if (token) {
            const getOrderHistory = async () => {
                if (isAdmin) {
                    const res = await axios.get('/orders', {
                        headers: {
                            Authorization: token
                        }
                    })
                    setOrders(res.data)
                } else {
                    const res = await axios.get('/api/user/orders', {
                        headers: {
                            Authorization: token
                        }
                    })
                    setOrders(res.data)
                }

            }
            getOrderHistory()
        }
    }, [token, callback, isAdmin])

    // Update user cart
    const addToCart = async (product) => {
        if (!isLoggedIn) return alert('Please log in to buy')
        const checkCart = cart.every(item => {
            return item._id !== product._id
        })

        if (checkCart) {
            setCart([...cart, {...product, quantity: 1}])

            await axios.patch('/api/user/addcart', {
                cart: [...cart, {...product, quantity: 1}]
            }, {
                headers: {
                    Authorization: token
                }
            })
        } else {
            alert('Product has been added')
        }
    }

    const addToFavs = async (product) => {
        if (!isLoggedIn) return alert('Please log in to add to your favs')

        const favs = favorites.every(item => {
            return item._id !== product._id
        })

        if (favs) {
            setFavorites([...favorites, {...product}])

            await axios.patch('/api/user/addfavs', {
                favorites: [...favorites, {...product}]
            }, {
                headers: {
                    Authorization: token
                }
            })
        } else {

            let newFavorites = favorites.filter(fav => fav._id !== product._id);
            setFavorites(newFavorites);

            await axios.patch('/api/user/addfavs', {
                favorites: newFavorites
            }, {
                headers: {
                    Authorization: token
                }
            })
        }
    }

    return {
        isLoggedIn: [isLoggedIn, setIsLoggedIn],
        isAdmin: [isAdmin, setIsAdmin],
        addToCart: addToCart,
        cart: [cart, setCart],
        addToFavs: addToFavs,
        favorites: [favorites, setFavorites],
        user: [user, setUser],
        orders: [orders, setOrders],
        callback: [callback, setCallback],
        darkMode: [darkMode, setDarkMode]
    }
};

export default UserApi;