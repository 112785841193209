import {useEffect, useState} from "react";
import axios from "axios";

const CategoriesApi = () => {
    const [categories, setCategories] = useState();
    const [callBack, setCallback] = useState(false)

    useEffect(() => {
        const getCategories = async () => {
            const res = await axios.get(`/categories`)
            setCategories(res.data)
        }
        getCategories()
    }, [callBack])

    return {
        categories: [categories, setCategories],
        callBack: [callBack, setCallback]
    }
};

export default CategoriesApi;