import {useState, useContext, useEffect} from "react";
import {GlobalState} from "../../store/Globals";
import axios from "axios";
import Loading from "../Loading/Loading";
import {PayPalButton} from "react-paypal-button-v2";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Checkout = ({history}) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userApi.cart;
    const [total, setTotal] = useState(0);
    const [sdkReady, setSdkReady] = useState(false);
    const [notes, setNotes] = useState('');
    const [address, setAddress] = useState('');
    const [token] = state.token;
    const [callback, setCallback] = state.userApi.callback;
    const [user] = state.userApi.user;

    const errMessage = err => {
        toast.error(err.message)
    }


    // Update user cart
    const addToCart = async () => {
        await axios.patch(('/api/user/addcart'), {cart: []}, {
            headers: {
                Authorization: token
            }
        })
    }

    const successfulPayment = async (paymentResult) => {
        if (paymentResult.status === 'COMPLETED') {
            const payerInfo = {
                payer: paymentResult.payer,
                orderId: paymentResult.id,
                total: user.membership === true ? total : total + 8
            }
            if (address.length < 1) toast.error('Please fill in address')
            await axios.post(('/orders'), {
                    payerInfo,
                    address,
                    notes,
                    user: user._id,
                    cart
                },
                {
                    headers: {
                        Authorization: token
                    }
                })

            setCart([])
            await addToCart()
            setCallback(!callback)
            if (typeof window !== 'undefined') {
                window.location.href = "/order/thanks";
            }
        }
    }
    useEffect(() => {
        // Set user address
        if (user.address) setAddress(user.address)

        // Get user cart total
        const getTotal = () => {

            const total = cart.reduce((prev, item) => {
                return prev + (item.price * item.quantity)
            }, 0b0)
            setTotal(total)
        }
        getTotal()

        const paypalScript = async () => {
            const {data: clientId} = await axios.get('/api/config/paypal')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
            script.async = true
            script.onload = () => {
                setSdkReady(true)
            }
            document.body.appendChild(script)
        }
        if (!window.paypal) {
            paypalScript()
        } else {
            setSdkReady(true)
        }

        if (user.mailingAddress !== undefined && user.city !== undefined && user.state !== undefined && user.zipCode) {
            setAddress(`${user.mailingAddress}  ${user.city}  ${user.state}, ${user.zipCode}`)
        }

    }, [cart, user.membership, user.mailingAddress, user.city, user.state, user.zipCode, user.address])

    return (
        <>
            {!sdkReady ? <Loading/> : (
                <>
                    <ToastContainer/>
                    <div className={`d-flex justify-content-center`}>
                        <label htmlFor="address">Shipping Address</label>
                        <input type="text" id={'address'}
                               value={address}
                               placeholder={'Please enter your shipping address'}
                               onChange={e => setAddress(e.target.value)}
                               required={true}
                               className={'mx-3'}
                        />
                        <label htmlFor="notes">Note to P.C.S</label>
                        <input type="text"
                               id={'notes'}
                               value={notes}
                               onChange={e => setNotes(e.target.value)}
                               className={'mx-3'}
                        />
                    </div>
                    {address.length > 0 && (
                        <section className={'text-center'}>
                            <PayPalButton amount={user.membership === true ? total : total + 8}
                                          onSuccess={successfulPayment}
                                          onError={err => {
                                              errMessage(err)
                                          }}/>
                        </section>
                    )}
                </>
            )}
            <div>

            </div>
        </>
    );
};

export default Checkout;