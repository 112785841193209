import {useContext, useEffect} from "react";
import {Link} from 'react-router-dom'
import {GlobalState} from "../../store/Globals";
import styles from './Navbar.module.css'
import axios from "axios";

const Navbar = () => {
    const state = useContext(GlobalState)
    const [isLoggedIn, setIsLoggedIn] = state.userApi.isLoggedIn;
    const [isAdmin, setIsAdmin] = state.userApi.isAdmin;
    const [cart] = state.userApi.cart
    const [user] = state.userApi.user

    const logoutUser = async () => {
        await axios.get('/api/user/logout')
        setIsLoggedIn(false)
        setIsAdmin(false);
        localStorage.removeItem('pcs-login')
        if (typeof window !== 'undefined') {
            window.location.href = "/";
        }
    }
    useEffect(() => {

    }, [isAdmin, isLoggedIn])
    const {avatar} = user;

    const loggedInRouter = () => {
        return (
            <>
                {isLoggedIn && !isAdmin && (
                    <>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to={"#"} id="navbarDropdownMenuLink"
                                  role="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                My Dashboard
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><Link className="dropdown-item" to={"/user/dashboard"}>
                                    <img src={avatar} alt="" width={'30px'} height={'30px'}/> Profile</Link></li>
                                <li><Link className="dropdown-item" to={"#"} onClick={logoutUser}>Logout</Link></li>
                            </ul>
                        </li>
                    </>
                )}
                {isAdmin && (
                    <>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to={"#"} id="navbarDropdownMenuLink"
                                  role="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                Admin
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><Link className="dropdown-item" to={"/admin/dashboard"}>Admin Panel</Link></li>
                                <li><Link className="dropdown-item" to={"#"} onClick={logoutUser}>Logout</Link></li>
                            </ul>
                        </li>
                    </>
                )}
            </>
        )
    }

    return (
        <header>
            <nav className={`navbar navbar-expand-lg navbar-light ${styles.bgPurity}`}>
                <div className="container">
                    <Link to={'/'} className={`${styles.logo} navbar-brand`}>Purity Cottage <span>Skincare</span></Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className={`navbar-nav mx-auto ${styles.navbarListContainer}`}>
                            <li>
                                <Link to={'/services'} className={`nav-link`}>Services</Link>
                            </li>
                            <li>
                                <Link to={'/products'} className={`nav-link`}>Products</Link>
                            </li>
                            <li>
                                <Link to={'/membership'}
                                      className={`nav-link`}>Membership</Link>
                            </li>
                            <li>
                                <Link to={'/guidelines'}
                                      className={`nav-link`}>Guidelines</Link>
                            </li>

                            {cart.length > 0 && (
                                <li>

                                    <Link to={'/cart'}
                                          className={`nav-link`}>Cart: {cart.length}</Link>

                                </li>
                            )}

                            {isLoggedIn ? loggedInRouter() : <li>
                                <Link to='/user/login' className={'nav-link'}>Login</Link>
                            </li>}


                            {/*<li className="nav-item dropdown">*/}
                            {/*    <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"*/}
                            {/*          role="button"*/}
                            {/*          data-bs-toggle="dropdown" aria-expanded="false">*/}
                            {/*        {user ? (*/}
                            {/*            user.name*/}
                            {/*        ) : <FaUser/>}*/}
                            {/*    </Link>*/}
                            {/*    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
                            {/*        <li><Link className="dropdown-item" to="/user/profile">Profile</Link></li>*/}
                            {/*        <li><Link className="dropdown-item" to="#">Logout</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;