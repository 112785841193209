import {useContext, useEffect, useState} from "react";
import {GlobalState} from "../../store/Globals";
import Layout from "../../components/Layouts/Layout";
import axios from "axios";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {toast, ToastContainer} from "react-toastify"; // Import css
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from "react-router-dom";


const CategoriesPage = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesApi.categories;
    const [category, setCategory] = useState('');
    const [categoryOrder, setCategoryOrder] = useState('');
    const [showCreateCategory, setShowCreateCategory] = useState(false);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [token] = state.token
    const isAdmin = state.userApi.isAdmin
    const [callBack, setCallback] = state.categoriesApi.callBack
    const [editExistingCategory, setEditExistingCategory] = useState(false)
    const [id, setId] = useState('')
    const history = useHistory();

    useEffect(() => {
        if (!isAdmin) history.push('/')
    }, [history, isAdmin])

    const createNewCategory = async (e) => {
        e.preventDefault()
        try {
            if (editExistingCategory) {
                const res = await axios.put((`/categories/${id}`), {name: category}, {
                    headers: {
                        Authorization: token
                    }
                })
                toast.success(res.data.msg)
                setShowCreateCategory(false)
            } else {
                const res = await axios.post(('/categories'), {name: category, order: categoryOrder}, {
                    headers: {
                        Authorization: token
                    }
                })
                toast.success(res.data.msg)
            }
            setEditExistingCategory(false)
            setCategory('')
            setCategoryOrder('')
            setCallback(!callBack)
        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    const editCategory = async (id, name) => {
        setId(id);
        setCategory(name)
        setShowCreateCategory(true)
        setEditExistingCategory(true)
    }

    // Remove category
    const removeItem = async (categoryId) => {

        confirmAlert({
            title: `Confirm delete category`,
            message: `Are you sure you want to delete this category?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteCategory(categoryId)
                    }
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        })
    }

    const deleteCategory = async (categoryId) => {
        try {
            await axios.delete((`/categories/${categoryId}`), {
                headers: {
                    Authorization: token
                }
            })
            toast.success(`Category Deleted`)
            setCallback(!callBack)

        } catch (err) {
            toast.error(err.response.data.msg)
        }
    }

    return (
        <Layout>
            <section>
                <button className={`btn btn-sm btn-outline-dark`} onClick={history.goBack}>Back to Dashboard</button>
            </section>
            <div className={'text-center my-3'}>
                <button className={'btn btn-sm btn-outline-dark mr-2'}
                        onClick={() => setShowCreateCategory(!showCreateCategory)}>Create Category
                </button>
                <button className={'btn btn-sm btn-outline-dark ml-2'}
                        onClick={() => setShowAllCategories(!showAllCategories)}>Manage Categories
                </button>

                <ToastContainer/>
            </div>
            {showCreateCategory && (
                <>
                    <section>
                        <form onSubmit={createNewCategory}>
                            <label htmlFor="category">Category Name</label>
                            <input type="text" value={category} onChange={e => setCategory(e.target.value)}/>
                            <label htmlFor="categoryOrder">Order</label>
                            <input type="text" value={categoryOrder} onChange={e => setCategoryOrder(e.target.value)}/>
                            <button className={'btn btn-sm btn-success'}
                                    disabled={category.length < 1}>{!editExistingCategory ? 'Create Category' : 'Update Category'}
                            </button>
                        </form>
                    </section>
                </>
            )}
            <section>
                {showAllCategories && (
                    <>

                        {categories && categories.map(category => (
                            <div key={category._id}>
                                <p>Category: {category.name}</p>
                                <button className={'btn btn-sm btn-secondary'}
                                        onClick={() => editCategory(category._id, category.name)}>Edit
                                </button>
                                <button className={'btn btn-sm btn-danger'} onClick={() => removeItem(category._id)}>Delete</button>
                            </div>
                        ))}
                    </>
                )}
            </section>
        </Layout>
    );
};

export default CategoriesPage;