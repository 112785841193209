import styles from './ContactInfo.module.css'
import {FaFacebook, FaInstagram} from "react-icons/all";

const ContactInfo = () => {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div className={`${styles.topHeading} container`}>
            <div className={`${styles.topHeadingContact}`}>
                <p>Contact Us: | <a href="tel: 8487776849">848.777.6849</a></p>
            </div>
            {/*<div className={`${styles.stayConnected}`}>*/}
            {/*    <p>Connect With Us:</p>*/}
            {/*    <p onClick={() => openInNewTab(`https:www.facebook.com/puritycottage`)}><FaFacebook/></p>*/}
            {/*    <p onClick={() => openInNewTab(`https:www.instagram.com/puritycottageskincare`)}><FaInstagram/></p>*/}
            {/*</div>*/}
        </div>
    );
};

export default ContactInfo;